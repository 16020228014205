import { Skeleton } from "baseui/skeleton";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { Layout } from "../../components";
import {
  BreadcrumbContainer,
  ButtonsWrapper,
  DetailsContainer,
  DetailsWrapper,
  LeftColumn,
  ProductRatesSkeleton,
  ProductWrapper,
  RightColumn,
  FeaturedProductsColumn
} from "./Product.styled";

const overrides = {
  Root: {
    style: () => ({
      borderRadius: "5px"
    })
  }
};

const ProductSkeleton = () => {
  return (
    <Layout>
      <DetailsContainer>
        <DetailsWrapper>
          <DetailsWrapper>
            <BreadcrumbContainer>
              <Skeleton
                height="20px"
                width="300px"
                animation
                overrides={overrides}
              />
            </BreadcrumbContainer>
            <ProductWrapper>
              <LeftColumn>
                <BrowserView>
                  <Skeleton
                    height="500px"
                    width="100%"
                    animation
                    overrides={overrides}
                  />
                </BrowserView>
                <MobileView>
                  <Skeleton
                    height="400px"
                    width="100%"
                    animation
                    overrides={overrides}
                  />
                </MobileView>
              </LeftColumn>
              <RightColumn>
                <div style={{ marginBottom: "8px" }}>
                  <BrowserView>
                    <Skeleton
                      height="20px"
                      width="300px"
                      animation
                      overrides={overrides}
                    />
                  </BrowserView>
                </div>
                <div style={{ marginBottom: "8px" }}>
                  <BrowserView>
                    <Skeleton
                      height="20px"
                      width="200px"
                      animation
                      overrides={overrides}
                    />
                  </BrowserView>
                  <MobileView>
                    <Skeleton
                      height="10px"
                      width="50%"
                      animation
                      overrides={overrides}
                    />
                  </MobileView>
                </div>
                <>
                  <BrowserView>
                    <Skeleton
                      height="20px"
                      width="100px"
                      animation
                      overrides={overrides}
                    />
                  </BrowserView>
                </>
                <BrowserView>
                  <ButtonsWrapper>
                    <Skeleton
                      height="40px"
                      width="100%"
                      animation
                      overrides={overrides}
                    />
                    <Skeleton
                      height="40px"
                      width="100%"
                      animation
                      overrides={{
                        Root: {
                          style: () => ({
                            borderRadius: "4px",
                            marginTop: "16px"
                          })
                        }
                      }}
                    />
                  </ButtonsWrapper>
                </BrowserView>
              </RightColumn>
              <FeaturedProductsColumn />
            </ProductWrapper>
            <ProductRatesSkeleton>
              <Skeleton
                height="150px"
                width="500px"
                animation
                overrides={overrides}
              />
            </ProductRatesSkeleton>
          </DetailsWrapper>
        </DetailsWrapper>
      </DetailsContainer>
    </Layout>
  );
};

export default ProductSkeleton;

import moment from "moment";

export const formatDate = (dateString) => {
  return moment(dateString).format("ll");
};

export const commentsFormat = (timeString) => {
  const date = moment.unix(timeString / 1000);
  const fromNow = moment(date).fromNow();
  return moment(date).calendar(null, {
    sameElse: "ll",
    lastWeek: "dddd, h:mma",
    sameDay: () => `[${fromNow}]`
  });
};

export const reviewDate = (dateString) => {
  const date = moment.unix(dateString / 1000);
  return moment(date).format("ll");
};

import styled from "styled-components";
import { mediumGray, white, secondaryRed } from "../../utils";
import { Body2, Subtitle2, Body3 } from "../../components";

export const DetailsContainer = styled.div``;
export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  overflow-x: hidden;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 0;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LeftColumn = styled.div`
  order: 1;
  width: 40%;
  margin-top: 2rem;
  border-radius: 4px;
  background-color: ${white};
  @media screen and (max-width: 720px) {
    flex: 1 0 50%;
    margin-top: 0;
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  flex-direction: column;
  border-radius: 4px;
  width: 35%;
  order: 2;
  margin-left: 0.5em;
  margin-top: 2rem;
  background-color: ${white};
  padding: 0 16px;
  @media screen and (max-width: 720px) {
    margin-left: 0px;
    margin-top: 20px;
    padding: 0px;
    flex: 1 0 50%;
    width: 100%;
  }
`;

export const FeaturedProductsColumn = styled.div`
  flex-direction: column;
  width: 25%;
  order: 3;
  margin-left: 0.5em;
  margin-top: 2rem;
  border-left: 1px solid #00000010;
  padding: 0 24px;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;
export const FeaturedProductsColumnWrapper = styled.div`
  height: 50vh;
  overflow-y: scroll;
`;

export const ProductTitle = styled(Subtitle2)`
  text-transform: capitalize;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  @media screen and (max-width: 720px) {
    font-size: 18px;
    width: 330px;
  }
`;

export const ProductProvider = styled(Body2)`
  margin-bottom: 8px;
  color: ${mediumGray};
  font-weight: 600;
  &:hover {
    color: ${secondaryRed};
  }
  @media screen and (max-width: 720px) {
    margin-bottom: 0.25rem;
  }
`;

export const ProductPrice = styled(Subtitle2)`
  font-weight: 700;
  @media screen and (max-width: 720px) {
    font-size: 1.25rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 25px 0;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledTitle = styled(Subtitle2)`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  @media screen and (max-width: 720px) {
    display: flex;
    font-size: 20px;
    flex-direction: column;
  }
`;

export const ProductReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 0;
  }
`;

export const ProductRatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${white};
  border-radius: 4px;
  /* margin: 16px 0; */
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProductRateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`;

export const TotalRateContainer = styled.div`
  margin-bottom: 8px;
`;

export const StyledTotalRatesText = styled(Body3)`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5rem;
  margin-left: 4px;
  align-self: flex-end;
`;

export const StyledTotalRatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`;

export const StyledTotalReviewText = styled.h4`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
`;

export const ButtonRateWrapper = styled.div`
  /* display: flex; */
  margin-left: auto;
  margin-top: 32px;
  @media screen and (max-width: 720px) {
    margin-left: unset;
    justify-content: center;
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #00000010;
`;

export const VerticalDivider = styled.div`
  height: 100%;
  margin: 1rem 0;
  border-left: 1px solid #00000010;
`;

export const StyledRatingText = styled(Body2)`
  font-size: 3rem;
  line-height: 48px;
  font-weight: 600;
`;

export const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const ProductRatesSkeleton = styled.div`
  margin: 24px 0;
  gap: 1rem;
`;

export const LeftColumnSkeleton = styled.div`
  display: flex;
  flex: 1 0 50%;
  margin-top: 2rem;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const RightColumnSkeleton = styled.div`
  display: flex;
  flex: 1 0 50%;
  margin-left: 1.5em;
  margin-top: 2rem;
  @media screen and (max-width: 720px) {
    margin-left: 20px;
    margin-top: 20px;
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 4px 16px 4px 16px;
  background-color: ${white};
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 8%);
`;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StatefulTabs, Tab, FILL } from "baseui/tabs-motion";
import LoginPage from "./Login";
import RegisterPage from "./Register";

import { AuthContainer } from "./Auth.styled";

export const AuthWrapper = styled.div`
  display: flex;
  background-color: white;
  padding: 16px 32px;
  box-shadow: 0px 3px 8px -4px rgb(0 0 0 / 16%);
  @media screen and (max-width: 720px) {
    box-shadow: unset;
    padding: 0;
  }
`;

const LogoContainer = styled.div`
  margin: 32px 0;
  @media screen and (max-width: 720px) {
    margin: 32px 0 16px 0;
  }
`;

const HeaderLogo = styled.img`
  display: flex;
  height: 40px;
  width: 100%;
`;

export default function AuthPage() {
  return (
    <AuthContainer>
      <LogoContainer>
        <Link to="/">
          <HeaderLogo src={"https://media.graphcms.com/k6o8wBkZSIeqBrhZk450"} />
        </Link>
      </LogoContainer>
      <AuthWrapper>
        <StatefulTabs fill={FILL.fixed}>
          <Tab title="Log in">
            <LoginPage />
          </Tab>
          <Tab title="Sign Up">
            <RegisterPage />
          </Tab>
        </StatefulTabs>
      </AuthWrapper>
    </AuthContainer>
  );
}

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Avatar } from "baseui/avatar";

import { useAuth } from "../../contexts/AuthContext";
import Queries from "../../graphql/queries";
import {
  ProductCard,
  Loading,
  EmptyState,
  ErrorComponent,
  Layout,
  Body1,
  Subtitle1
} from "../../components";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";
// import { average } from "../../utils";

const FavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 40px;
  @media screen and (max-width: 720px) {
    padding: 16px;
    margin: 0;
  }
  /* background-color: #f9fafa; */
`;

const FavoritesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
  background-color: #ffffff;
  padding: 32px;
  min-width: 10rem;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 /8%);
  @media screen and (max-width: 720px) {
    flex-direction: column;
    padding: 16px;
    justify-content: center;
    box-shadow: unset;
    margin-bottom: 16px;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    justify-content: center;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-right: 1rem;
  @media screen and (max-width: 720px) {
    margin-right: unset;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-bottom: 1px solid #eeeeee;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 32px 0;
  @media screen and (max-width: 720px) {
    margin: 8px 0;
  }
`;

const StyledNameText = styled(Subtitle1)`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 720px) {
    margin-bottom: 0.3rem;
    text-align: center;
  }
`;

const StyledFavoriteText = styled(Body1)`
  opacity: 50%;
  @media screen and (max-width: 720px) {
    text-align: center;
  }
`;

const EmptyStateContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`;

export default function UserFavorites() {
  useEffect(() => {
    analytics.logEvent(Log.screens.FAVORITE_PAGE_VIEWED);
  }, []);
  const { user } = useAuth();
  const history = useHistory();
  const { loading, error, data } = useQuery(Queries.GET_FAVORITES, {
    variables: {
      userId: user.user.id
    }
  });

  if (loading)
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  if (error)
    return (
      <Layout>
        <ErrorComponent error={error.message} />{" "}
      </Layout>
    );

  return (
    <Layout>
      <FavoritesContainer>
        <FavoritesWrapper>
          <ProfileContainer>
            <AvatarWrapper>
              <Avatar name={user.user.name} size="scale1400" />
            </AvatarWrapper>
            <ProfileWrapper>
              <StyledNameText>{user.user.name}</StyledNameText>
              <StyledFavoriteText>
                {`${data.favorites.length} Favorites`}
              </StyledFavoriteText>
            </ProfileWrapper>
          </ProfileContainer>
          <Divider />
          <ProductsContainer>
            {data && data.favorites.length > 0 ? (
              data.favorites.map((f) => {
                const { id, imageUrl, name, price, provider } = f.product;
                {
                  /* const rating = reviews?.map((review) =>
                  parseInt(review.rating)
                ); */
                }
                return (
                  <Link to={`/product/${id}`} key={id}>
                    <ProductCard
                      key={id}
                      imageUrl={imageUrl}
                      title={name.toLowerCase()}
                      provider={provider?.name}
                      price={price}
                      // rating={average(rating)}
                      // reviews={reviews.length}
                    />
                  </Link>
                );
              })
            ) : (
              <EmptyStateContainer>
                <EmptyState
                  firstTitle="You don't have any favorite products yet."
                  buttonAction={() => history.push("/")}
                  buttonLabel="Add your first product"
                />
              </EmptyStateContainer>
            )}
          </ProductsContainer>
        </FavoritesWrapper>
      </FavoritesContainer>
    </Layout>
  );
}

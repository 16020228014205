import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";
import Loadable from "react-loadable";
import PropTypes from "prop-types";
import styled from "styled-components";
import BlogPage from "./containers/Blog/Blog";
import AuthPage from "./containers/Auth/Auth";
import BlogDetails from "./containers/Blog/BlogDetails";
import UserFavorites from "./containers/User/Favorites";
import Users from "./containers/Admin/Users/Users";
import Category from "./containers/Categories/Category";
import Home from "./containers/Home/Home";
import ProductDetails from "./containers/Products/ProductDetail";
import {
  PrivacyPolicy,
  ElectronicCommunicationsPolicy,
  AntiDiscriminationPolicy,
  TermsOfService,
  OurStory
} from "./containers/Pages";
import { AdminLayout } from "./components";
import { ProviderPage } from "./containers/ProductProviders";
import { useAuth } from "./contexts/AuthContext";
import Search from "./containers/Search";

const Main = styled.main`
  min-height: calc(100% - 210px);
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { user } = useAuth();
  const isAuthenticated = user && user.user && user.user.name;
  console.log("isAuthenticated", isAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const Loading = () => <div>...loading</div>;

const AsyncAllCategories = Loadable({
  loader: () => import("./containers/Categories/AllCategories"),
  loading: Loading
});

const AsyncAdmin = Loadable({
  loader: () => import("./containers/Admin/Products/Products"),
  loading: Loading
});

const AsyncAdminCategories = Loadable({
  loader: () => import("./containers/Admin/Categories/Categories"),
  loading: Loading
});

const AsyncAdminProviders = Loadable({
  loader: () => import("./containers/Admin/Providers/ProductProviders"),
  loading: Loading
});

const AsyncAdminProvidersReviews = Loadable({
  loader: () => import("./containers/Admin/ProductReviews/ProductReviews"),
  loading: Loading
});

const AsyncAdminCommentReviews = Loadable({
  loader: () => import("./containers/Admin/BlogComments/Comments"),
  loading: Loading
});

export default function Routing() {
  return (
    <Router>
      <ScrollToTop />
      <Main>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/product/:id?" component={ProductDetails} />
          <Route path="/blog/:slug" component={BlogDetails} />
          <Route path="/blog" component={BlogPage} />s
          <Route path="/auth" component={AuthPage} />
          <Route path="/search" component={Search} />
          <Route path="/favorites" component={UserFavorites} />
          <Route path="/category/:id?" component={Category} />
          <Route path="/all-categories" component={AsyncAllCategories} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/provider/:id?" component={ProviderPage} />
          <Route
            path="/electronic-communications-policy"
            component={ElectronicCommunicationsPolicy}
          />
          <Route
            path="/anti-discrimination-policy"
            component={AntiDiscriminationPolicy}
          />
          <Route path="/our-story" component={OurStory} />
          <Route
            render={(props) => (
              <AdminLayout {...props}>
                <Switch>
                  <PrivateRoute path="/admin">
                    <AsyncAdmin />
                  </PrivateRoute>
                  <Route path="/products" exact component={AsyncAdmin} />
                  <Route path="/categories" component={AsyncAdminCategories} />
                  <Route path="/providers" component={AsyncAdminProviders} />
                  <Route
                    path="/product-reviews"
                    component={AsyncAdminProvidersReviews}
                  />
                  <Route
                    path="/blog-comments"
                    component={AsyncAdminCommentReviews}
                  />
                  <Route path="/users" component={Users} />
                </Switch>
              </AdminLayout>
            )}
          />
        </Switch>
      </Main>
    </Router>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node
};

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "baseui/button";

import {
  DisplayContainer,
  DisplayWrapper,
  StyledH1,
  StyledH3
} from "./Display.styled";

export const ErrorComponent = ({ error }) => {
  return (
    <DisplayContainer style={{ minHeight: "80vh" }}>
      <DisplayWrapper>
        <StyledH1>{"Didn't get 'em"}</StyledH1>
        <StyledH3>
          Thank you for visiting Snkmart.com. Unfortunately, it didn&apos;t work
          this time and this is our error page.{" "}
        </StyledH3>
        <Link to="/">
          <Button>Go home</Button>
        </Link>
        {APP_ENV !== "production" && (
          <StyledH3 style={{ color: "red" }}>{error}</StyledH3>
        )}
      </DisplayWrapper>
    </DisplayContainer>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.string.isRequired
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Drawer } from "baseui/drawer";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { PLACEMENT, Toast, KIND as kind, ToasterContainer } from "baseui/toast";
import { Button, KIND } from "baseui/button";

import Mutations from "../../../graphql/mutations";
import {
  PageTitle,
  InputWrapper,
  InputContainer,
  ButtonWrapper,
  ModalWrapper
} from "../Admin.styled";

function UpdateUser({ isOpen, setIsOpen, userObj }) {
  const [id, setUserId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const [UpdateUser, { loading, error }] = useMutation(Mutations.UPDATE_USER);

  useEffect(() => {
    const { id, name, email, role } = userObj && userObj;
    setUserId(id);
    setName(name);
    setEmail(email);
    setRole(role);
  }, [userObj]);

  const handleUpdateUser = async (event) => {
    event.preventDefault();
    try {
      await UpdateUser({
        variables: {
          id,
          name,
          email,
          role,
          password
        }
      });
      setIsOpen(!isOpen);
    } catch (error) {
      return (
        <ToasterContainer placement={PLACEMENT.bottomLeft}>
          <Toast kind={kind.negative}>{error.message}</Toast>
        </ToasterContainer>
      );
    }
  };

  if (error) {
    return (
      <ToasterContainer placement={PLACEMENT.bottomLeft}>
        <Toast kind={kind.negative}>{error.message}</Toast>
      </ToasterContainer>
    );
  }

  return (
    <Drawer
      size={"550px"}
      isOpen={isOpen}
      autoFocus
      animate
      onClose={() => setIsOpen(false)}
    >
      <ModalWrapper>
        <PageTitle>Update User</PageTitle>
        <InputContainer>
          <InputWrapper>
            <FormControl label={() => "User Name"}>
              <Input
                id="name"
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <FormControl label={() => "Email"}>
              <Input
                id="email"
                name="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />
            </FormControl>
          </InputWrapper>
          <PageTitle>User Role</PageTitle>
          <InputWrapper>
            <RadioGroup
              value={role}
              onChange={(e) => setRole(e.target.value)}
              name="role"
              align={ALIGN.vertical}
            >
              <Radio value="admin" description="Store Administrator">
                Admin
              </Radio>
              <Radio value="basic" description="Store User">
                Basic
              </Radio>
            </RadioGroup>
          </InputWrapper>
          <PageTitle>User Password</PageTitle>
          <InputWrapper>
            <FormControl label={() => "Password"}>
              <Input
                id="password"
                name="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </FormControl>
          </InputWrapper>
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={() => setIsOpen(false)} kind={KIND.tertiary}>
            Cancel
          </Button>
          <Button isLoading={loading} onClick={handleUpdateUser}>
            Update User
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Drawer>
  );
}

UpdateUser.propTypes = {
  userObj: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.oneOf(["admin", "basic"]),
    password: PropTypes.string
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};

export default UpdateUser;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { primaryBlack } from "../../utils";

const Button = styled.button`
  font-family: "Work Sans", sans-serif;
  display: inline-block;
  outline: none;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  height: 48px;
  border-style: solid;
  border: 1px solid ${primaryBlack};
  background: transparent;
  margin-bottom: 16px;
`;

const SocialIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  cursor: "pointer";
`;

const SocialIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const LabelWrapper = styled.div`
  margin-left: 8px;
`;

export const SocialButton = ({ label, iconImage, ...props }) => {
  return (
    <Button {...props}>
      <SocialIconContainer>
        <SocialIcon src={iconImage} />
        <LabelWrapper>{label}</LabelWrapper>
      </SocialIconContainer>
    </Button>
  );
};

SocialButton.propTypes = {
  iconImage: PropTypes.string,
  label: PropTypes.string
};

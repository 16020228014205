import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Queries from "../../graphql/queries";
import { primaryBlack, white, primaryRed } from "../../utils";

const NavWrapper = styled.nav`
  display: flex;
  width: 100%;
  background-color: ${white};
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 8px -4px rgb(0 0 0 / 15%);
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const LinksWrapper = styled.nav`
  display: flex;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  flex-direction: row;
`;

const Navlink = styled(NavLink)`
  font-weight: 600;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding-right: 13px;
  padding-left: 13px;
  color: ${primaryBlack};
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  text-decoration: none;
  align-items: center;
  &:hover {
    opacity: 0.9;
    text-decoration: underline;
    transform: translate3d(0px, -1px, 0px);
  }
  &:focus {
    color: ${primaryRed};
  }
  &:active {
    color: ${primaryRed};
  }
`;

export const NavHeader = () => {
  const [categories, setCategories] = useState([]);
  const { data } = useQuery(Queries.GET_CATEGORIES);
  useEffect(() => {
    let controller = new AbortController();
    setCategories(data && data.categories);
    return () => controller?.abort();
  }, [data]);

  return (
    <>
      <NavWrapper>
        <LinksWrapper>
          <ul>
            {categories &&
              categories.slice(0, 5).map((category) => (
                <Navlink
                  id="headerLinks"
                  className="headerLinks"
                  label="Categories"
                  to={`/category/${category.id}` || "/"}
                  key={category.id}
                  activeStyle={{
                    color: primaryRed
                  }}
                >
                  {category.name}
                </Navlink>
              ))}
            <Navlink
              id="headerLinks"
              className="headerLinks"
              label="All Categories"
              activeStyle={{
                color: primaryRed
              }}
              to={"/all-categories"}
            >
              All Categories
            </Navlink>
            <Navlink
              id="headerLinks"
              className="headerLinks"
              label="Blog"
              to={"/blog"}
              activeStyle={{
                color: primaryRed
              }}
            >
              Blog
            </Navlink>
          </ul>
        </LinksWrapper>
      </NavWrapper>
    </>
  );
};

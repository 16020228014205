import React, { useEffect } from "react";
import styled from "styled-components";

import { Layout } from "../../components";
import Blog from "./sections/BlogSection/Blog";
import HeroSlider from "./sections/HeroSection/Hero";
import {
  FeaturedProducts,
  Products
} from "./sections/ProductsSection/Products";
import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;
  justify-content: center;
  transition: all 0.25s ease-out;
  @media screen and (max-width: 720px) {
    margin: 0;
    justify-content: center;
    padding: 16px;
  }
`;

const Home = () => {
  useEffect(() => {
    analytics.logEvent(Log.screens.HOME_PAGE_VIEWED, {
      screen: "home"
    });
  }, []);
  return (
    <Layout>
      <HeroSlider />
      <HomeWrapper>
        <FeaturedProducts />
        <Products />
        <Blog />
      </HomeWrapper>
    </Layout>
  );
};

export default Home;

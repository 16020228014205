import gql from "graphql-tag";

const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    stage
    id
    updatedAt
    publishedAt
    title
    slug
    excerpt
    content {
      markdown
    }
    tags
    coverImage {
      id
      url
    }
    author {
      id
      name
      picture {
        id
        url
        authorAvatar {
          id
          picture {
            id
            url
          }
        }
      }
    }
  }
`;

const USER_FRAGMENT = gql`
  fragment UserFragment on LocalUser {
    id
    name
    email
    role
    imageUrl
    socialLogin
  }
`;

const AUTH_SUCCESS_RESPONSE_FRAGMENT = gql`
  fragment AuthSuccessResponseFragment on AuthSuccessResponse {
    message
    status
    access_token
    refresh_token
    error
    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const COMMENT_THREAD_FRAGMENT = gql`
  fragment CommentFields on Comment {
    id
    text
    parentCommentId
    postId
    approved
    createdAt
    commentBy {
      id
      name
      imageUrl
    }
  }
`;

const COMMENTS_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    text
    parentCommentId
    approved
    postId
    createdAt
    commentBy {
      id
      name
      imageUrl
    }
    thread {
      ...CommentFields
      thread {
        ...CommentFields
        thread {
          ...CommentFields
          thread {
            ...CommentFields
            thread {
              ...CommentFields
            }
          }
        }
      }
    }
  }
  ${COMMENT_THREAD_FRAGMENT}
`;

const HERO_IMAGES = gql`
  fragment HeroFragment on HeroImagesWithLinkConnection {
    edges {
      node {
        id
        stage
        updatedAt
        active
        createdAt
        heroLink
        image {
          id
          fileName
          url
        }
      }
    }
  }
`;

const FAVORITE_FRAGMENT = gql`
  fragment FavoriteFragment on Favorite {
    id
    favoritedBy
    product {
      id
      name
      url
      price
      imageUrl
      description
      provider {
        id
        name
      }
    }
  }
`;

const REVIEW_FRAGMENT = gql`
  fragment ReviewFragment on Review {
    id
    review
    rating
    subject
    createdAt
    approved
    product {
      id
      name
      imageUrl
    }
    reviewBy {
      id
      name
      imageUrl
    }
  }
`;

const PRODUCT_FRAGMENT = gql`
  fragment ProductsFragment on Product {
    id
    name
    price
    url
    imageUrl
    description
    enlisted
    featured
    reviews {
      id
      rating
    }
    provider {
      id
      name
      products {
        id
        name
        imageUrl
      }
    }
    category {
      id
      name
      products {
        id
        name
      }
    }
  }
`;

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    description
    imageUrl
    products {
      ...ProductsFragment
      id
      name
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const PROVIDERS_FRAGMENT = gql`
  fragment ProviderFragment on Provider {
    id
    name
    imageUrl
    url
    products {
      ...ProductsFragment
      id
      name
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const PAGE_FRAGMENT = gql`
  fragment PageFragment on Page {
    stage
    id
    updatedAt
    publishedAt
    title
    slug
    content {
      markdown
    }
  }
`;

export default {
  POST_FRAGMENT,
  AUTH_SUCCESS_RESPONSE_FRAGMENT,
  USER_FRAGMENT,
  COMMENTS_FRAGMENT,
  COMMENT_THREAD_FRAGMENT,
  HERO_IMAGES,
  FAVORITE_FRAGMENT,
  REVIEW_FRAGMENT,
  PRODUCT_FRAGMENT,
  CATEGORY_FRAGMENT,
  PROVIDERS_FRAGMENT,
  PAGE_FRAGMENT
};

import gql from "graphql-tag";

import FRAGMENTS from "./fragments";

const SOCIAL_AUTH = gql`
  mutation socialAuth(
    $provider: String!
    $email: String!
    $name: String!
    $imageUrl: String
    $token: String
    $action: String!
  ) {
    socialAuth(
      params: {
        provider: $provider
        email: $email
        name: $name
        imageUrl: $imageUrl
        token: $token
        action: $action
      }
    ) {
      ...AuthSuccessResponseFragment
    }
  }
  ${FRAGMENTS.AUTH_SUCCESS_RESPONSE_FRAGMENT}
`;

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    loginUser(params: { email: $email, password: $password }) {
      ...AuthSuccessResponseFragment
    }
  }
  ${FRAGMENTS.AUTH_SUCCESS_RESPONSE_FRAGMENT}
`;

const REGISTER = gql`
  mutation register(
    $name: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
  ) {
    signupUser(
      params: {
        name: $name
        email: $email
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      ...AuthSuccessResponseFragment
    }
  }
  ${FRAGMENTS.AUTH_SUCCESS_RESPONSE_FRAGMENT}
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String!) {
    refreshToken(params: { refresh_token: $refresh_token }) {
      ...AuthSuccessResponseFragment
    }
  }
  ${FRAGMENTS.AUTH_SUCCESS_RESPONSE_FRAGMENT}
`;

const CREATE_COMMENT = gql`
  mutation createComment($text: String!, $postId: ID!, $parentCommentId: ID) {
    createComment(
      params: {
        text: $text
        postId: $postId
        parentCommentId: $parentCommentId
      }
    ) {
      ...CommentFragment
    }
  }
  ${FRAGMENTS.COMMENTS_FRAGMENT}
`;

const UPDATE_COMMENT = gql`
  mutation updateComment($commentId: ID!, $approved: Boolean!) {
    updateComment(commentId: $commentId, approved: $approved) {
      ...CommentFragment
    }
  }
  ${FRAGMENTS.COMMENTS_FRAGMENT}
`;

const CREATE_FAVORITE = gql`
  mutation createFavorite($productId: ID!, $userId: ID!) {
    createFavorite(params: { productId: $productId, userId: $userId }) {
      ...FavoriteFragment
    }
  }
  ${FRAGMENTS.FAVORITE_FRAGMENT}
`;

const DELETE_FAVORITE = gql`
  mutation removeFavorite($favoriteId: ID!) {
    removeFavorite(params: { favoriteId: $favoriteId }) {
      message
      operation
    }
  }
`;

const CREATE_REVIEW = gql`
  mutation createReview(
    $review: String!
    $subject: String!
    $userId: ID!
    $rating: Int!
    $productId: ID!
  ) {
    createReview(
      params: {
        review: $review
        userId: $userId
        rating: $rating
        subject: $subject
        productId: $productId
      }
    ) {
      ...ReviewFragment
    }
  }
  ${FRAGMENTS.REVIEW_FRAGMENT}
`;

const DELETE_REVIEW = gql`
  mutation deleteReview($reviewId: ID!) {
    deleteReview(params: { reviewId: $reviewId }) {
      message
      operation
    }
  }
`;

const UPDATE_REVIEW = gql`
  mutation updateReview($reviewId: ID!, $approved: Boolean!) {
    updateReview(reviewId: $reviewId, approved: $approved) {
      ...ReviewFragment
    }
  }
  ${FRAGMENTS.REVIEW_FRAGMENT}
`;

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $url: String!
    $imageUrl: String
    $price: String!
    $description: String
    $enlisted: Boolean
    $featured: Boolean
    $categoryId: ID
    $providerId: ID
  ) {
    createProduct(
      params: {
        name: $name
        url: $url
        imageUrl: $imageUrl
        price: $price
        description: $description
        enlisted: $enlisted
        featured: $featured
        categoryId: $categoryId
        providerId: $providerId
      }
    ) {
      ...ProductsFragment
    }
  }
  ${FRAGMENTS.PRODUCT_FRAGMENT}
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $productId: ID!
    $name: String
    $url: String
    $imageUrl: String
    $price: String
    $description: String
    $enlisted: Boolean
    $featured: Boolean
    $categoryId: ID
    $providerId: ID
  ) {
    updateProduct(
      params: {
        productId: $productId
        name: $name
        url: $url
        imageUrl: $imageUrl
        price: $price
        description: $description
        enlisted: $enlisted
        featured: $featured
        categoryId: $categoryId
        providerId: $providerId
      }
    ) {
      ...ProductsFragment
    }
  }
  ${FRAGMENTS.PRODUCT_FRAGMENT}
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: ID!) {
    deleteProduct(productId: $productId) {
      message
      operation
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $description: String
    $imageUrl: String
  ) {
    createCategory(
      params: { name: $name, description: $description, imageUrl: $imageUrl }
    ) {
      ...CategoryFragment
    }
  }
  ${FRAGMENTS.CATEGORY_FRAGMENT}
`;

const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $categoryId: ID!
    $name: String
    $description: String
    $imageUrl: String
  ) {
    updateCategory(
      params: {
        categoryId: $categoryId
        name: $name
        description: $description
        imageUrl: $imageUrl
      }
    ) {
      ...CategoryFragment
    }
  }
  ${FRAGMENTS.CATEGORY_FRAGMENT}
`;

const DELETE_CATEGORY = gql`
  mutation deleteCategory($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId) {
      message
      operation
    }
  }
`;

const CREATE_PROVIDER = gql`
  mutation createProvider($name: String!, $imageUrl: String!, $url: String) {
    createProvider(params: { name: $name, imageUrl: $imageUrl, url: $url }) {
      ...ProviderFragment
    }
  }
  ${FRAGMENTS.PROVIDERS_FRAGMENT}
`;

const UPDATE_PROVIDER = gql`
  mutation updateProvider(
    $providerId: ID!
    $name: String
    $imageUrl: String
    $url: String
  ) {
    updateProvider(
      params: {
        providerId: $providerId
        name: $name
        imageUrl: $imageUrl
        url: $url
      }
    ) {
      ...ProviderFragment
    }
  }
  ${FRAGMENTS.PROVIDERS_FRAGMENT}
`;

const DELETE_PROVIDER = gql`
  mutation deleteProvider($providerId: ID!) {
    deleteProvider(providerId: $providerId) {
      message
      operation
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $email: String
    $role: Role
    $password: String
  ) {
    updateUser(
      params: {
        id: $id
        name: $name
        email: $email
        role: $role
        password: $password
      }
    ) {
      ...UserFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`;

export default {
  SOCIAL_AUTH,
  LOGIN,
  REGISTER,
  REFRESH_TOKEN,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  CREATE_REVIEW,
  DELETE_REVIEW,
  UPDATE_REVIEW,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  UPDATE_USER
};

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Body2, H6, Body3, Caption } from "../../components";
import { primaryBlack, white } from "../../utils";

export const AuthContainer = styled.div`
  display: flex;
  background-color: #f8f8f8;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px 40px;
  @media screen and (max-width: 720px) {
    background-color: ${white};
    padding: 0;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 320px;
  flex-direction: column;
  padding-bottom: 32px;
  margin-top: 16px;
  background-color: white;
  @media screen and (max-width: 720px) {
    box-shadow: none;
    width: 100%;
  }
`;

export const AuthTitle = styled(H6)`
  text-align: center;
  margin-bottom: 8px;
`;

export const AuthSubtitle = styled(Body2)`
  margin-bottom: 24px;
  text-align: center;
`;

export const TextDivider = styled.h3`
  text-transform: capitalize;
  font-weight: 400;
  color: ${primaryBlack};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  padding: 4px 0;
`;

export const AccountText = styled(Body3)`
  margin: 8px 0;
  display: inline-block;
  width: inherit;
`;

export const TermsText = styled(Caption)`
  margin: 8px 0 16px 0;
`;

export const AccountLinkText = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useAlert } from "react-alert";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { Button, KIND } from "baseui/button";
import { Accordion, Panel } from "baseui/accordion";
import { Skeleton } from "baseui/skeleton";
import Markdown from "markdown-to-jsx";
import { BrowserView, MobileView } from "react-device-detect";

import { ChevronRight, Heart } from "react-feather";

import { useAuth } from "../../contexts/AuthContext";
import Mutations from "../../graphql/mutations";
import Queries from "../../graphql/queries";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

import {
  ProductDetailCard,
  Rate,
  Layout,
  MetaDecorator,
  ErrorComponent,
  FeaturedProduct,
  Subtitle3,
  Body2
} from "../../components";

import {
  primaryBlack,
  userFavorite,
  userFavoriteId,
  average,
  checkPrice
} from "../../utils";

import {
  DetailsContainer,
  DetailsWrapper,
  ProductWrapper,
  LeftColumn,
  RightColumn,
  ProductTitle,
  ProductProvider,
  ProductPrice,
  ButtonsWrapper,
  ProductRatesContainer,
  StyledTitle,
  ProductRateWrapper,
  TotalRateContainer,
  StyledTotalRatesText,
  ButtonRateWrapper,
  ProductReviewContainer,
  StyledRatingText,
  StyledTotalRatesWrapper,
  BreadcrumbContainer,
  Divider,
  FeaturedProductsColumn,
  VerticalDivider,
  FeaturedProductsColumnWrapper
} from "./Product.styled";

import ProductReview from "./ProductReviews";
import ReviewModal from "./ReviewModal";
import ProductSkeleton from "./Skeleton";

export default function ProductDetails() {
  const { id } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const isAuthenticated = user && user.user && user.user.name;
  const [isFavorite, setFavorite] = useState(null);
  const [favoriteId, setFavoriteId] = useState(null);
  const [rating, setRating] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [getFavorites, { data: dataFavorites }] = useLazyQuery(
    Queries.GET_FAVORITES,
    {
      variables: {
        userId: user && user.user && user.user.id
      }
    }
  );

  const {
    loading: loadingReviews,
    data: reviewData,
    error: reviewError
  } = useQuery(Queries.GET_REVIEWS, {
    variables: {
      productId: id
    }
  });

  // get all products using productID
  const { loading, error, data } = useQuery(Queries.GET_PRODUCT_BY_ID, {
    variables: { productId: id }
  });

  // get all products and filter by featured
  const {
    // loading: loadingProducts,
    error: productsError,
    data: productsData
  } = useQuery(Queries.GET_PRODUCTS);

  const firedEvents = new Set();

  const once = (event, properties) => {
    if (firedEvents.has(event) || firedEvents.length > 0) {
      return;
    }

    analytics.logEvent(event, properties);
    firedEvents.add(event);
  };

  useEffect(() => {
    once(Log.screens.PRODUCT_DETAIL_PAGE_VIEWED, {
      screen_name: "Product Detail"
    });
    getFavorites();
    setFavoriteId(
      userFavoriteId(dataFavorites?.favorites && dataFavorites.favorites, id)
    ); // save favoriteId to state for delete mutation if true.
    setFavorite(userFavorite(dataFavorites && dataFavorites.favorites, id)); // iterate and check if the current product is favoriteId.
    setRating(
      reviewData && reviewData?.reviews.map((review) => parseInt(review.rating))
    );
  }, [getFavorites, dataFavorites, isFavorite, reviewData]);

  // create favorite mutation
  const [
    createFavorite,
    { loading: loadingFavorite, error: errorFavorite }
  ] = useMutation(Mutations.CREATE_FAVORITE);

  // delete favorite mutation
  const [deleteFavorite] = useMutation(Mutations.DELETE_FAVORITE);

  // add to favorites when user is authenticated.
  const handleSubmitFavorite = async () => {
    if (!isAuthenticated) {
      return history.push({
        pathname: "/auth",
        state: { from: location }
      });
    }
    try {
      if (isFavorite !== true) {
        await createFavorite({
          variables: {
            productId: id,
            userId: user.user.id
          }
        });
        setFavorite(true);
      }
    } catch (error) {
      console.log("==============>", error.message, typeof error.message);
      if (
        error.message &&
        error.message.includes("Operation not permitted. Unauthorized")
      )
        console.log("Operation not permitted. Unauthorized");
      else
        alert.error(
          "Uh, oh, an error was encountered. sWe're looking into the issue."
        );
    }
  };

  // delete favorite record
  const handleDeleteFavorite = async () => {
    try {
      await deleteFavorite({
        variables: {
          favoriteId: favoriteId[0]
        }
      });
      setFavorite(false);
      alert.info(
        <div style={{ textTransform: "initial" }}>Removed from Favorites</div>
      );
    } catch (error) {
      alert.error("Uh, oh, an error was encountered");
    }
  };

  const handleAddProductReview = () => {
    if (!isAuthenticated) {
      return history.push({
        pathname: "/auth",
        state: { from: location }
      });
    }
    setOpen(!isOpen);
  };

  if (!data && loading) return <ProductSkeleton />;
  if (error)
    return (
      <Layout>
        <ErrorComponent error={error.message} />
      </Layout>
    );
  if (productsError) return <ErrorComponent error={productsError.message} />;
  if (errorFavorite) return alert.error(errorFavorite.message);

  return (
    <Layout>
      <MetaDecorator
        title={data.product.name}
        description={data.product.description || data.product.name}
        imageUrl={data.product.imageUrl}
        imageAlt={data.product.name}
      />
      <ReviewModal
        isOpen={isOpen}
        setOpen={setOpen}
        productId={id}
        userId={user && user.user && user.user.id}
      />
      <DetailsContainer>
        <DetailsWrapper>
          <BreadcrumbContainer>
            <Breadcrumbs>
              <StyledLink href="/">Home</StyledLink>
              <StyledLink href="#">Product</StyledLink>
              <span>{data.product.name}</span>
            </Breadcrumbs>
          </BreadcrumbContainer>
          <ProductWrapper>
            <LeftColumn>
              <ProductDetailCard
                title={data.product.name}
                imageUrl={data.product.imageUrl}
              />
            </LeftColumn>
            <RightColumn>
              <ProductTitle>{data.product.name}</ProductTitle>
              <Link to={`/provider/${data.product.provider.id}`} key={id}>
                <ProductProvider>
                  From: {data.product.provider.name}
                </ProductProvider>
              </Link>
              <ProductPrice>{checkPrice(data.product?.price)}</ProductPrice>
              <>
                <ButtonsWrapper>
                  <Button
                    $as="a"
                    href={data.product?.url}
                    target="_blank"
                    rel="nofollow noreferrer"
                    endEnhancer={() => (
                      <ChevronRight size="20" title="Buy Product" />
                    )}
                  >
                    Shop now
                  </Button>

                  <Button
                    isLoading={loadingFavorite}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          marginTop: "16px"
                        })
                      }
                    }}
                    endEnhancer={() =>
                      isFavorite ? (
                        <Heart
                          size="20"
                          color={primaryBlack}
                          title="Favorite product"
                        />
                      ) : (
                        <Heart size="20" title="Favorite product" />
                      )
                    }
                    onClick={
                      isFavorite ? handleDeleteFavorite : handleSubmitFavorite
                    }
                    kind={KIND.secondary}
                  >
                    {isFavorite ? "Favorited" : "Favorite"}
                  </Button>
                </ButtonsWrapper>
              </>
              <BrowserView>
                <Divider style={{ margin: "2rem 0 1rem 0" }} />
                {data.product.description && (
                  <>
                    <Accordion renderAll>
                      <Panel
                        title={<StyledTitle>Description</StyledTitle>}
                        overrides={{
                          Header: {
                            style: () => ({
                              paddingLeft: "0px"
                            })
                          },
                          Content: {
                            style: () => ({
                              backgroundColor: "#fff",
                              paddingLeft: "0px"
                            })
                          }
                        }}
                      >
                        {
                          <Markdown options={{ forceBlock: true }}>
                            {data.product.description}
                          </Markdown>
                        }
                      </Panel>
                    </Accordion>
                    {/* <Divider /> */}
                  </>
                )}
              </BrowserView>
            </RightColumn>
            <VerticalDivider />
            <FeaturedProductsColumn>
              <Subtitle3>Featured Products</Subtitle3>
              <FeaturedProductsColumnWrapper>
                {productsData &&
                  productsData.products
                    .filter(
                      (filteredProducts) => filteredProducts.featured === true
                    )
                    .map((product) => {
                      const { id, imageUrl, name, price } = product;
                      return (
                        <div key={id}>
                          <Link to={`/product/${id}`} key={id}>
                            <FeaturedProduct
                              imageUrl={imageUrl}
                              title={name.toLowerCase()}
                              price={price}
                            />
                          </Link>
                        </div>
                      );
                    })}
              </FeaturedProductsColumnWrapper>
            </FeaturedProductsColumn>
          </ProductWrapper>
          <ProductReviewContainer>
            <Divider />
            <MobileView>
              {data.product.description && (
                <>
                  <Accordion
                    renderAll
                    overrides={{
                      Header: {
                        style: () => ({
                          paddingLeft: "0px"
                        })
                      },
                      Content: {
                        style: () => ({
                          backgroundColor: "#fff",
                          paddingLeft: "0px"
                        })
                      }
                    }}
                  >
                    <Panel
                      title={<StyledTitle>Product Description</StyledTitle>}
                    >
                      {<Body2>{data.product.description}</Body2>}
                    </Panel>
                  </Accordion>
                </>
              )}
            </MobileView>
            <ProductRatesContainer>
              <ProductRateWrapper>
                <StyledTitle>Ratings</StyledTitle>
                <TotalRateContainer>
                  <Rate readOnly size={24} value={rating && average(rating)} />
                </TotalRateContainer>
                <StyledTotalRatesWrapper>
                  {!rating ? (
                    <BrowserView>
                      <Skeleton
                        height="40px"
                        width="150px"
                        animation
                        overrides={{
                          Root: {
                            style: () => ({
                              borderRadius: "20px"
                            })
                          }
                        }}
                      />
                    </BrowserView>
                  ) : (
                    <>
                      <StyledRatingText>
                        {rating && average(rating)}
                      </StyledRatingText>
                      <StyledTotalRatesText>{"out of 5 "}</StyledTotalRatesText>
                    </>
                  )}
                </StyledTotalRatesWrapper>
              </ProductRateWrapper>
              <ButtonRateWrapper>
                <Button
                  onClick={() => handleAddProductReview()}
                  kind={KIND.secondary}
                >
                  Write a Review
                </Button>
              </ButtonRateWrapper>
            </ProductRatesContainer>
          </ProductReviewContainer>
          <ProductReview
            reviewData={reviewData}
            loadingReviews={loadingReviews}
            reviewError={reviewError}
            setOpen={setOpen}
            isOpen={isOpen}
          />
        </DetailsWrapper>
      </DetailsContainer>
    </Layout>
  );
}

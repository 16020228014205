import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";

import Queries from "../../graphql/queries";
import Mutations from "../../graphql/mutations";
import { TextInput, TextArea, Rate, H3, Body2 } from "../../components";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const StyledRatingTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const RatingWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledSubject = styled(H3)`
  font-weight: 500;
  margin-bottom: 1rem;
`;

const StyledDescriptionTitle = styled(H3)`
  font-weight: 500;
  margin-bottom: 1rem;
`;

export default function ReviewModal({ productId, userId, isOpen, setOpen }) {
  useEffect(() => {
    analytics.logEvent(Log.screens.PRODUCT_REVIEW_PAGE_VIEWED);
  }, []);
  const [review, setReview] = useState("");
  const [subject, setSubject] = useState("");
  const [rating, setRating] = useState(0);
  const [createReview, { loading: isAddingReview }] = useMutation(
    Mutations.CREATE_REVIEW,
    {
      update: (cache, result) => {
        const reviewData = cache.readQuery({
          query: Queries.GET_REVIEWS,
          variables: {
            productId: productId
          }
        });
        cache.writeQuery({
          query: Queries.GET_REVIEWS,
          variables: {
            productId: productId
          },
          data: {
            reviews: [...reviewData.reviews, result.data.createReview]
          }
        });
      }
    }
  );

  // save review to state
  const handleInput = (event) => {
    setReview(event.target.value);
  };

  // save rate value
  const handleSubjectInput = (event) => {
    setSubject(event.target.value);
  };

  // submit review
  const handleSubmitReview = async (e) => {
    e.preventDefault();
    await createReview({
      variables: {
        review: review,
        userId: userId,
        rating: rating,
        subject: subject,
        productId: productId
      }
    });
    setReview("");
    setSubject("");
    setRating(0);
    setOpen(false);
  };

  return (
    <>
      <ModalContainer>
        <Modal
          onClose={() => setOpen(!isOpen)}
          closeable
          isOpen={isOpen}
          animate
          autoFocus
          unstable_ModalBackdropScroll
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalHeader>Add a Rating and Review</ModalHeader>
          <ModalHeader>
            <Body2>
              Help the buying experience for members like yourself and leave a
              legit review!
            </Body2>
          </ModalHeader>
          <ModalBody>
            <StyledRatingTitle>Rating</StyledRatingTitle>
            <RatingWrapper>
              <Rate
                size={30}
                onChange={(data) => setRating(data.value)}
                value={rating}
              />
            </RatingWrapper>
            <StyledSubject>Review Title</StyledSubject>
            <TextInput
              placeholder="Add Your Title (Optional)"
              required
              value={subject}
              onChange={handleSubjectInput}
            />
            <StyledDescriptionTitle>
              Tell us how you really feel!
            </StyledDescriptionTitle>
            <TextArea
              onChange={handleInput}
              placeholder="Add Your Review (Optional)"
              value={review}
            />
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => setOpen(false)}
              kind={ButtonKind.tertiary}
            >
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isAddingReview}
              onClick={handleSubmitReview}
              disabled={!review.trim() || !rating}
            >
              Submit Review
            </ModalButton>
          </ModalFooter>
        </Modal>
      </ModalContainer>
    </>
  );
}

ReviewModal.propTypes = {
  productId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

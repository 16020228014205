import styled from "styled-components";

import { primaryBlack } from "../../utils";

const H1Styles = `
font-size: 2.25rem;
line-height: 100%;
color: ${primaryBlack};

`;

const H2Styles = `
font-size: 1.375rem;
line-height: 90%;
color: ${primaryBlack};
`;

const H3Styles = `
font-size: 1rem;
line-height: 140%;
font-weight: 500;
color: ${primaryBlack};
`;

const H4Styles = `
font-size: 0.75rem;
line-height: 140%;
color: ${primaryBlack};
letter-spacing: 0.5px;
text-transform: uppercase;
`;

const FontStyle = `
font-family: "Work Sans", sans-serif;
margin:0;
`;

export const H1 = styled.h1`
  ${FontStyle}
  ${H1Styles}
  @media screen and (max-width: 720px) {
    font-size: 1.875rem;
  }
`;

export const H2 = styled.h2`
  ${H2Styles}
  ${FontStyle}
  font-weight: 500;
`;

export const H3 = styled.h3`
  ${H3Styles}
  ${FontStyle}
  line-height: 90%;
  font-weight: 500;
`;

export const H4 = styled.h4`
  ${FontStyle}
  ${H4Styles}
  font-weight: 500;
  line-height: 90%;
  font-family: "Work Sans", sans-serif;
`;

export const H5 = styled.h5`
  ${FontStyle}
  ${H4Styles}
  font-weight: 500;
  line-height: 90%;
  font-family: "Work Sans", sans-serif;
`;

export const H6 = styled.h6`
  ${FontStyle}
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 100%;
  @media screen and (max-width: 720px) {
    font-size: 1rem;
  }
`;

export const Subtitle1 = styled.h6`
  ${FontStyle}
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 150%;
  @media screen and (max-width: 720px) {
    font-size: 1.25rem;
  }
`;

export const Subtitle2 = styled.h6`
  ${FontStyle}
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 150%;
  @media screen and (max-width: 720px) {
    font-size: 1rem;
  }
`;

export const Subtitle3 = styled.h6`
  ${FontStyle}
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  color: #333333;
  @media screen and (max-width: 720px) {
    font-size: 0.875rem;
  }
`;

export const Body1 = styled.p`
  ${FontStyle}
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 720px) {
    font-size: 0.875rem;
  }
`;

export const Body2 = styled.p`
  ${FontStyle}
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
`;

export const Body3 = styled.p`
  ${FontStyle}
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;
`;

export const Caption = styled.h6`
  ${FontStyle}
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 120%;
  @media screen and (max-width: 720px) {
    font-size: 0.5rem;
  }
`;

export const userFavorite = (favorites, productId) => {
  if (favorites && favorites?.length === 0) return false;
  const product = favorites && favorites.map((r) => r.product);
  const favorited = product && product.find((r) => r.id === productId);
  return !!favorited;
};

export const userFavoriteId = (favorites, productId) => {
  const userFavorites =
    favorites && favorites.filter((r) => r.product.id === productId);
  const favoriteId = userFavorites && userFavorites.map((f) => f.id);
  return favoriteId;
};

export const average = (rating) => {
  const total = rating.reduce((acc, c) => acc + c, 0);

  if (total === 0) {
    return 0;
  }

  const sum = total / rating.length;
  return Number(sum.toFixed(1));
};

export function checkPrice(price) {
  if (price.includes("From")) {
    price.replace("$", " ");
    return price;
  }
  if (price.charAt(0) !== "$") {
    return "$" + price;
  }
  return price;
}

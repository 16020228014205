import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ListItem, ListItemLabel, ARTWORK_SIZES } from "baseui/list";
import ChevronRight from "baseui/icon/chevron-right";
import { LogOut } from "react-feather";
import AuthUtils from "../../../utils/Auth";
import { useAuth } from "../../../contexts/AuthContext";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

const CategoriesContainer = styled.div``;

const MenuWrapper = styled.div`
  /* margin: 1rem 0; */
`;

const MenuItems = ({ categories, setOpen, open }) => {
  const { user, updateUser, updateTokens } = useAuth();
  const isAuthenticated = user && user.user && user.user.name;
  const handleLogout = (event) => {
    if (isAuthenticated) {
      event.preventDefault();
      AuthUtils.clearStorage();
      updateTokens({});
      updateUser({});
      setOpen(!open);
      history.push("/");
    }
  };
  return (
    <MenuContainer>
      <CategoriesContainer>
        {categories &&
          categories.slice(0, 5).map((category) => (
            <MenuWrapper key={category.id}>
              <Link
                id="headerLinks"
                className="headerLinks"
                label="Categories"
                onClick={() => setOpen(!open)}
                to={`/category/${category.id}` || "/"}
              >
                <ListItem Enhancer={() => <ChevronRight />}>
                  <ListItemLabel> {category.name}</ListItemLabel>
                </ListItem>
              </Link>
            </MenuWrapper>
          ))}
        <MenuWrapper>
          <Link
            id="headerLinks"
            className="headerLinks"
            label="categories"
            to={"/all-categories"}
          >
            <ListItem>
              <ListItemLabel>All Categories</ListItemLabel>
            </ListItem>
            {/* <Title></Title> */}
          </Link>
        </MenuWrapper>
        <MenuWrapper>
          <Link
            id="headerLinks"
            className="headerLinks"
            label="blog"
            to={"/blog"}
          >
            <ListItem>
              <ListItemLabel>Blog</ListItemLabel>
            </ListItem>
          </Link>
        </MenuWrapper>
        {isAuthenticated ? (
          <MenuWrapper onClick={handleLogout}>
            <ListItem
              artwork={(props) => <LogOut {...props} />}
              artworkSize={ARTWORK_SIZES.SMALL}
            >
              <ListItemLabel>Logout</ListItemLabel>
            </ListItem>
          </MenuWrapper>
        ) : (
          <>
            <Link to="/auth">
              <ListItem>
                <ListItemLabel>Sign or Sign Up</ListItemLabel>
              </ListItem>
            </Link>
          </>
        )}
      </CategoriesContainer>
    </MenuContainer>
  );
};

MenuItems.defaultProps = {
  categories: []
};

MenuItems.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default MenuItems;

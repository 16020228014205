import React, { useState } from "react";
import AuthUtils from "../utils/Auth";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [user, setUser] = useState({ user: JSON.parse(AuthUtils.getUser()) });
  const [tokens, setTokens] = useState({
    accessToken: AuthUtils.getAccessToken(),
    refreshToken: AuthUtils.getRefreshToken()
  });

  const updateUser = (u) => setUser(u);
  const updateTokens = (t) => setTokens(t);

  return (
    <AuthContext.Provider
      value={{ user, tokens, updateUser, updateTokens }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };

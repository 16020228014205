import {
  AuthSubtitle,
  AuthTitle,
  AuthWrapper,
  ButtonWrapper,
  TextDivider
} from "./Auth.styled";
import React, { useState } from "react";

import AuthUtils from "../../utils/Auth";
import { Button } from "baseui/button";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Mutations from "../../graphql/mutations";
import { SocialButton } from "../../components/Button/SocialButton";
import { TextInput } from "../../components/TextFields/TextInput";
import { useAlert } from "react-alert";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ChevronRight } from "react-feather";

export default function LoginPage() {
  const { updateTokens, updateUser } = useAuth();
  const alert = useAlert();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const [socialAuth, { loading: loadingSocialLogin }] = useMutation(
    Mutations.SOCIAL_AUTH
  );
  const [login, { loading: loadingLogin }] = useMutation(Mutations.LOGIN);
  const [loginInputs, setLoginInputs] = useState({});

  // login user using google
  const handleGoogleAuth = async (response) => {
    const action = "Login";
    const provider = "Google";
    const { error, profileObj, tokenId } = response;
    const inputs = {
      token: tokenId,
      profile: profileObj,
      status: null,
      error
    };

    try {
      const authResponse = await AuthUtils.handleAuth(
        socialAuth,
        inputs,
        action,
        provider,
        updateTokens,
        updateUser
      );

      if (authResponse && authResponse.status === 201) {
        let { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
      } else {
        if (authResponse.message !== 450) {
          alert.error(authResponse.message);
        }
      }
    } catch (error) {
      console.log(error);
      alert.error(`${action} failed. If not registered, please register.`);
    }
  };

  // login user using facebook
  /*   const handleFacebookAuth = async (response) => {
    const action = "Login";
    const provider = "Facebook";
    const { status, accessToken, email, name } = response;
    const inputs = {
      token: accessToken,
      profile: {
        email,
        name
      },
      status,
      error: null
    };

    try {
      const authResponse = await AuthUtils.handleAuth(
        socialAuth,
        inputs,
        action,
        provider,
        updateTokens,
        updateUser
      );

      if (authResponse && authResponse.status === 201) {
        history.replace(from);
      } else {
        if (authResponse.message !== 450) {
          alert.error(authResponse.message);
        }
      }
    } catch (error) {
      alert.error(`${action} failed. If not registered, please register.`);
    }
  }; */

  // save inputs to state
  const handleInput = (event) => {
    setLoginInputs({ ...loginInputs, [event.target.name]: event.target.value });
  };

  // login user using input form
  const handleSubmit = async (event) => {
    const errorMsg =
      "An error occured logging the user in. If not registered, please register.";
    const { email, password } = loginInputs;

    if ((email, password)) {
      event.preventDefault();

      try {
        const loginResponse = await AuthUtils.loginUser(
          login,
          loginInputs,
          errorMsg,
          updateTokens,
          updateUser
        );

        if (loginResponse && loginResponse.status === 201) {
          alert.info(loginResponse.message);
          history.replace(from);
        } else {
          alert.error(loginResponse.message);
        }
      } catch (error) {
        alert.error(errorMsg);
      }
    } else {
      alert.error("Please fill in all the fields");
    }
  };

  return (
    <>
      <AuthWrapper>
        <AuthTitle>Welcome Back!</AuthTitle>
        <AuthSubtitle>Log in to access this page</AuthSubtitle>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Continue with Google"
          render={(renderProps) => (
            <SocialButton
              // TODO put images in a dynamic place
              iconImage={
                "https://media.graphcms.com/output=format:jpg/RTyTL7QmR5qraKUWnK7G"
              }
              label="Continue with Google"
              onClick={renderProps.onClick}
            />
          )}
          onSuccess={handleGoogleAuth}
          onFailure={handleGoogleAuth}
          cookiePolicy={"single_host_origin"}
        />

        {/* <FacebookLogin
          render={(renderProps) => (
            // TODO put images in a dynamic place
            <SocialButton
              iconImage={
                "https://media.graphcms.com/output=format:jpg/NpyT5vKZTQyuoplrKUl8"
              }
              label="Continue with Facebook"
              onClick={renderProps.onClick}
            />
          )}
          scope={"email,public_profile"}
          appId={FACEBOOK_APP_ID}
          fields="name,email"
          callback={handleFacebookAuth}
          icon="fa-facebook"
        /> */}
        <TextDivider>OR</TextDivider>
        <TextInput
          placeholder="Email"
          name="email"
          id="email"
          required
          onChange={handleInput}
        />

        <TextInput
          placeholder="Password"
          name="password"
          id="password"
          type="password"
          required
          onChange={handleInput}
        />
        <ButtonWrapper>
          <Button
            isLoading={loadingSocialLogin || loadingLogin}
            onClick={handleSubmit}
            endEnhancer={() => <ChevronRight size="20" title="login" />}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </AuthWrapper>
    </>
  );
}

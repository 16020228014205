import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { Button, KIND } from "baseui/button";

import Queries from "../../graphql/queries";
import Mutations from "../../graphql/mutations";

import { useAuth } from "../../contexts/AuthContext";
import { commentsFormat, abbreviateName } from "../../utils";

import { Loading, Comments, TextArea, ErrorComponent } from "../../components";

import {
  AccountText,
  AccountLinkText,
  ThreadContainer,
  ButtonContainer
} from "./Blog.styled";

function Comment({ comment }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const alert = useAlert();
  const [reply, setReply] = useState("");
  const isAuthenticated = user && user.user && user.user.name;
  const role = user && user.user && user.user.role;
  const [visible, setVisible] = useState(2);
  const nestedComments = (comment?.thread?.slice(0, visible) || []).map(
    (comment) => {
      return <Comment key={comment.id} comment={comment} />;
    }
  );
  const [createComment, { loading: isAddingComment }] = useMutation(
    Mutations.CREATE_COMMENT,
    {
      update: (cache, result) => {
        const commentData = cache.readQuery({
          query: Queries.GET_COMMENTS,
          variables: {
            postId: comment.postId
          }
        });
        cache.writeQuery({
          query: Queries.GET_COMMENTS,
          variables: {
            postId: comment.postId
          },
          data: {
            comment: [...commentData.comment, result.data.createComment]
          }
        });
      }
    }
  );

  // update comment mutation: approve and disapprove comments
  const [
    updateComment,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(Mutations.UPDATE_COMMENT);

  if (mutationLoading) return <Loading />;
  if (mutationError) `Error! ${mutationError}`;

  // save comment to state
  const handleInput = (event) => {
    setReply(event.target.value);
  };

  // submit comment
  const handleSubmitComment = (e) => {
    e.preventDefault();
    createComment({
      variables: {
        text: reply,
        postId: comment.postId,
        parentCommentId: comment.id
      }
    });
    setOpen(!open);
  };

  // handle approve and disapprove of comments
  const handleUpdateComment = async (id, approved) => {
    if (approved === true) {
      const confirmed = window.confirm("Disapprove this comment?");
      if (!confirmed) {
        return;
      }
    }
    try {
      await updateComment({
        variables: {
          commentId: id,
          approved: !approved
        }
      });
    } catch (error) {
      alert.error("Uh, oh, an error was encountered");
    }
  };

  const loadMore = () => {
    setVisible((prev) => {
      return prev + 2;
    });
  };

  return (
    <>
      <Comments
        dateTime={commentsFormat(comment.createdAt)}
        author={abbreviateName(comment.commentBy && comment.commentBy.name)}
        text={comment.text}
        imageUrl={comment.commentBy?.imageUrl}
        open={open}
        setOpen={setOpen}
        approved={comment.approved}
        role={role}
        handleActions={() => handleUpdateComment(comment.id, comment.approved)}
      >
        <TextArea
          onChange={handleInput}
          value={reply}
          placeholder={`Type here to reply to ${
            comment.commentBy && comment.commentBy.name
          }`}
        />

        {!isAuthenticated ? (
          <AccountText>
            <AccountLinkText to="/auth">Sign in </AccountLinkText> or{" "}
            <AccountLinkText to="/auth">Sign up </AccountLinkText>
            to leave a reply.
          </AccountText>
        ) : (
          <ThreadContainer>
            <Button isLoading={isAddingComment} onClick={handleSubmitComment}>
              Reply
            </Button>
            <Button
              onClick={() => {
                setOpen(!open);
              }}
              kind={KIND.tertiary}
            >
              Dismiss
            </Button>
          </ThreadContainer>
        )}
      </Comments>
      <div style={{ marginLeft: "40px" }}>
        {nestedComments}
        {visible < comment?.thread?.length && (
          <ButtonContainer>
            <Button kind={KIND.tertiary} onClick={loadMore}>
              More Replies
            </Button>
          </ButtonContainer>
        )}
      </div>
    </>
  );
}

export default function BlogComments({ postId }) {
  const [comments, setComments] = useState([]);
  const [visible, setVisible] = useState(4);
  const { loading, error, data } = useQuery(Queries.GET_COMMENTS, {
    variables: {
      postId: postId
    }
  });

  useEffect(() => {
    setComments(data && data.comment);
  }, [data]);

  const loadMore = () => {
    setVisible((prev) => {
      return prev + 4;
    });
  };

  if (!data && loading) return <Loading />;
  if (error) return <ErrorComponent error={error.message} />;

  return (
    <>
      {comments &&
        comments.slice(0, visible).map((c) => {
          return <Comment key={c.id} comment={c} postId={postId} />;
        })}
      {visible < comments?.length && (
        <ButtonContainer style={{ justifyContent: "center" }}>
          <Button onClick={loadMore}>More Comments</Button>
        </ButtonContainer>
      )}
    </>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    postId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    thread: PropTypes.array,
    createdAt: PropTypes.string,
    approved: PropTypes.Boolean,
    commentBy: PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string
    })
  })
};

BlogComments.propTypes = {
  postId: PropTypes.string.isRequired
};

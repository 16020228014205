import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";

import AuthUtils from "../../utils/Auth";
import Mutations from "../../graphql/mutations";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "baseui/button";
import { SocialButton } from "../../components/Button/SocialButton";
import { TextInput } from "../../components/TextFields/TextInput";
import { ChevronRight } from "react-feather";

import {
  AuthWrapper,
  TextDivider,
  AuthTitle,
  AuthSubtitle,
  TermsText
} from "./Auth.styled";

export default function RegisterPage() {
  const { updateTokens, updateUser } = useAuth();
  const alert = useAlert();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const [socialAuth, { loading: loadingSocialAuth }] = useMutation(
    Mutations.SOCIAL_AUTH
  );
  const [register, { loading: loadingRegistration }] = useMutation(
    Mutations.REGISTER
  );
  const [regInputs, setRegInputs] = useState({});

  // register user using google
  const handleGoogleAuth = async (response) => {
    const action = "Register";
    const provider = "Google";
    const { error, profileObj, tokenId } = response;
    const inputs = {
      token: tokenId,
      profile: profileObj,
      status: null,
      error
    };

    try {
      const authResponse = await AuthUtils.handleAuth(
        socialAuth,
        inputs,
        action,
        provider,
        updateTokens,
        updateUser
      );

      if (authResponse && authResponse.status === 201) {
        history.replace(from);
      } else {
        if (authResponse.message !== 450) {
          alert.error(authResponse.message);
        }
      }
    } catch (error) {
      alert.error(`${action} failed. If already registered, please login.`);
    }
  };

  // register user using facebook
  /*   const handleFacebookAuth = async (response) => {
    const action = "Register";
    const provider = "Facebook";
    const { status, accessToken, email, name } = response;
    const inputs = {
      token: accessToken,
      profile: {
        email,
        name
      },
      status,
      error: null
    };

    try {
      const authResponse = await AuthUtils.handleAuth(
        socialAuth,
        inputs,
        action,
        provider,
        updateTokens,
        updateUser
      );

      if (authResponse && authResponse.status === 201) {
        history.replace(from);
      } else {
        if (authResponse.message !== 450) {
          alert.error(authResponse.message);
        }
      }
    } catch (error) {
      alert.error(`${action} failed. If already registered, please login.`);
    }
  }; */

  // save inputs to state
  const handleInput = (event) => {
    setRegInputs({ ...regInputs, [event.target.name]: event.target.value });
  };

  // register user using the input form
  const handleSubmit = async (event) => {
    const errorMsg =
      "An error occured registering the user. If already registered, please login.";
    const { name, email, password, password_confirmation } = regInputs;

    if ((name, email, password, password_confirmation)) {
      event.preventDefault();

      if (password !== password_confirmation || password.trim() === "") {
        alert.error("Passwords do not match");
        return null;
      }

      try {
        const registerResponse = await AuthUtils.registerUser(
          register,
          regInputs,
          errorMsg,
          updateTokens,
          updateUser
        );

        if (registerResponse && registerResponse.status === 201) {
          alert.info(registerResponse.message);
          history.replace(from);
        } else {
          alert.error(registerResponse.message);
        }
      } catch (error) {
        alert.error(errorMsg);
      }
    } else {
      alert.error("Please fill in all the fields");
    }
  };

  return (
    <>
      <>
        <AuthWrapper>
          <AuthTitle>Search your favorites products all in one place</AuthTitle>
          <AuthSubtitle>Join now - it’s free!</AuthSubtitle>
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleAuth}
            onFailure={handleGoogleAuth}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <SocialButton
                iconImage={
                  "https://media.graphcms.com/output=format:jpg/RTyTL7QmR5qraKUWnK7G"
                }
                label="Continue with Google"
                onClick={renderProps.onClick}
              />
            )}
          />
          {/* <FacebookLogin
            render={(renderProps) => (
              <SocialButton
                iconImage={
                  "https://media.graphcms.com/output=format:jpg/NpyT5vKZTQyuoplrKUl8"
                }
                label="Continue with Facebook"
                onClick={renderProps.onClick}
              />
            )}
            appId={FACEBOOK_APP_ID}
            fields="name,email"
            callback={handleFacebookAuth}
          /> */}
          <TextDivider>OR</TextDivider>
          <TextInput
            placeholder="Name"
            name="name"
            id="name"
            required
            onChange={handleInput}
          />
          <TextInput
            placeholder="Email"
            name="email"
            id="email"
            required
            onChange={handleInput}
          />
          <TextInput
            placeholder="Password"
            name="password"
            id="password"
            type="password"
            required
            onChange={handleInput}
          />
          <TextInput
            placeholder="Confirm Password"
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            required
            onChange={handleInput}
          />

          <TermsText>
            The authenticity and privacy of our members is the highest value to
            our marketplace. We do NOT sell your data to anyone and never will
          </TermsText>

          <Button
            isLoading={loadingSocialAuth || loadingRegistration}
            onClick={handleSubmit}
            endEnhancer={() => <ChevronRight size="20" title="login" />}
          >
            Continue
          </Button>
        </AuthWrapper>
      </>
    </>
  );
}

import React from "react";
import { useQuery } from "@apollo/client";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import {
  Body1,
  ErrorComponent,
  Layout,
  MetaDecorator,
  Loading,
  ProductCard
} from "../../components";
import Queries from "../../graphql/queries";
import { average } from "../../utils";
import {
  BreadcrumbContainer,
  ProviderContainer,
  ProviderWrapper,
  Divider,
  UnderLine,
  EmptyStateContainer,
  EmptyStateText,
  ProductWrapper,
  StyledH1,
  TotalText,
  StyledP
} from "./styles";

const ProviderPage = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(Queries.GET_PROVIDER, {
    variables: { providerId: id }
  });

  console.log("data", data);
  if (error) return <ErrorComponent error={error.message} />;
  if (loading)
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  return (
    <>
      <Layout>
        <ProviderContainer>
          <MetaDecorator title={data.provider.name} />
          <BreadcrumbContainer>
            <Breadcrumbs>
              <StyledLink to="/">Home</StyledLink>
              <span>Providers</span>
              <span>{data.provider.name}</span>
            </Breadcrumbs>
          </BreadcrumbContainer>
          <StyledP>Products by:</StyledP>
          <StyledH1>{data.provider.name}</StyledH1>
          <UnderLine />
          <Divider />
          <TotalText>
            {`${data.provider && data.provider.products.length} results`}
          </TotalText>
          <ProviderWrapper>
            <>
              <ProductWrapper>
                {data.provider && data.provider.products.length > 0 ? (
                  data.provider.products.map((product) => {
                    const {
                      id,
                      imageUrl,
                      name,
                      price,
                      provider,
                      reviews
                    } = product;

                    const rating = reviews?.map((review) =>
                      parseInt(review.rating)
                    );

                    return (
                      <Link to={`/product/${id}`} key={id}>
                        <ProductCard
                          showBorder
                          large
                          key={id}
                          imageUrl={imageUrl}
                          title={name.toLowerCase()}
                          provider={data.provider.name}
                          price={price}
                          providerId={provider.id}
                          rating={average(rating)}
                          reviews={reviews.length}
                        />
                      </Link>
                    );
                  })
                ) : (
                  <EmptyStateContainer>
                    <Body1>Sorry, your search did not match any products</Body1>
                    <EmptyStateText>
                      Try browsing some of our other Categories
                    </EmptyStateText>
                  </EmptyStateContainer>
                )}
              </ProductWrapper>
            </>
          </ProviderWrapper>
        </ProviderContainer>
      </Layout>
    </>
  );
};

export default ProviderPage;

import React, { useState } from "react";
import { Search } from "baseui/icon";
import { Input } from "baseui/input";
import { useHistory } from "react-router-dom";

export const SearchBar = ({ ...props }) => {
  const history = useHistory();
  const [searchParams, setSearchParams] = useState("");

  const handleChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      history.push(`/search?params=${searchParams}`);
    }
  };

  return (
    <Input
      startEnhancer={<Search size="24px" />}
      clearable
      type="text"
      name={name}
      value={searchParams}
      label="search-bar"
      placeholder={searchParams ? searchParams : "Search for Products"}
      onChange={handleChange}
      onKeyUp={handleSearch}
      {...props}
      overrides={{
        Root: {
          style: () => ({
            height: "40px",
            backgroundColor: "#fff",
            borderRadius: "2px"
          })
        },
        Input: {
          style: () => ({
            backgroundColor: "#fff"
          })
        },
        ClearIconContainer: {
          style: () => ({
            backgroundColor: "#fff"
          })
        },
        StartEnhancer: {
          style: () => ({
            backgroundColor: "#fff"
          })
        }
      }}
    ></Input>
  );
};

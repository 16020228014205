import React, { useEffect } from "react";

import { hot } from "react-hot-loader/root";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { ApolloLink } from "apollo-link";
import { setContext } from "@apollo/client/link/context";
import { onError } from "apollo-link-error";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ErrorBoundary } from "react-error-boundary";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { createTheme } from "baseui";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";

import Routing from "./routes";
import { GlobalStyle } from "./utils";
import { AuthProvider } from "./contexts/AuthContext";
import { FlagsProvider } from "./contexts/FlagsProvider";

import { ErrorFallback } from "./components";
import { analytics } from "./config/firebase";
import Log from "./config/analytics";

const engine = new Styletron();
const primitives = {
  primaryFontFamily: "Work Sans"
};

const theme = createTheme(primitives);

const httpLink = createHttpLink({
  uri: GRAPHQL_URL
});
const automaticPersistedQueryLink = createPersistedQueryLink();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
          null,
          2
        )}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  },
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  },
  mutate: {
    errorPolicy: "all"
  }
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("access_token");
  return {
    headers: {
      ...headers,
      authorization: token ? token : ""
    }
  };
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: "refreshToken",
  isTokenValidOrUndefined: () => {
    const token = localStorage.getItem("access_token") || null;
    if (!token) return true;
    try {
      const { exp } = jwtDecode(token);
      const expires = new Date(exp * 1000);
      if (Date.now() >= expires) return false;
      return true;
    } catch {
      return false;
    }
  },
  fetchAccessToken: async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    const resp = await fetch(GRAPHQL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `mutation refreshToken
        { refreshToken(params:
        {refresh_token: "${refreshToken}"})
       { access_token refresh_token } }`
      })
    });
    return resp;
  },

  handleFetch: (refreshToken) => {
    const { access_token, refresh_token } = refreshToken;

    if (!access_token || !refresh_token) {
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("user");
      return window.localStorage.removeItem("refresh_token");
    }

    window.localStorage.setItem("access_token", access_token);
    return window.localStorage.setItem("refresh_token", refresh_token);
  },

  handleError: () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("user");
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    refreshLink,
    errorLink,
    automaticPersistedQueryLink,
    authLink,
    httpLink
  ]),
  cache: new InMemoryCache(),
  version: "1.0.0",
  connectToDevTools: false,
  defaultOptions
});

// Alert configuration
const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    textTransform: "capitalize"
  }
};

const App = () => {
  useEffect(() => {
    analytics.setCurrentScreen(Log.screens.HOME_PAGE_VIEWED);
    analytics.logEvent(Log.events.APP_LAUNCHED, {
      screen: "HOME"
    });
  }, []);
  return (
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <FlagsProvider defaults={{}}>
              <AuthProvider>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                  <Routing />
                </AlertProvider>
              </AuthProvider>
            </FlagsProvider>
          </ErrorBoundary>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
};

export default hot(App);

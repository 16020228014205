import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { primaryBlack, gray, secondaryRed, white } from "../../utils";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 16px 32px;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  line-height: 18px;
  letter-spacing: 0.2px;
  height: 40px;
  border-radius: 0px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-style: solid;
  transition: transform 0.25s ease, box-shadow 0.25s ease,
    background-color 0.25s ease;
  &:hover {
    opacity: 0.8;
    transform: translate3d(0px, -1px, 0px);
  }
  ${(props) =>
    props.disabled
      ? `&:disabled {
    opacity: 0.6;
  }`
      : ""}
`;

const StyledButton = styled(Button)`
  background-color: ${primaryBlack};
  color: ${white};
  border: none;
  font-weight: 600;
`;

const StyledSecondaryButton = styled(Button)`
  background-color: ${secondaryRed};
  color: ${white};
  border: none;
`;

const StyledAlternativeButton = styled(Button)`
  background: none;
  border: 1px solid ${gray};
  color: ${primaryBlack};
  &:hover {
    border: 1px solid ${primaryBlack};
  }
`;

const StyledLinkButton = styled(Button)`
  display: flex;
  flex-direction: row;
  background: none;
  color: ${primaryBlack};
  align-items: center;
  border: none;
  color: ${primaryBlack};
  font-weight: 600;
  &:hover {
  }
`;

const ButtonIcon = styled.div`
  /* justify-content: center;
  align-items: center; */
`;

const defaultProps = {
  disabled: false,
  loading: false
};

const propTypes = {
  /**
    The content to display inside the button.
    */
  label: PropTypes.string,
  /**
   Disables the button, disallowing any interaction.
   */
  disabled: PropTypes.bool,
  /**
  Replaces button text with a `Looding...` while a background action is being performed.
    */
  loading: PropTypes.bool,
  icon: PropTypes.node
};

export const PrimaryButton = ({ label, disabled, loading, icon, ...props }) => {
  return (
    <StyledButton disabled={disabled} {...props}>
      {loading ? "Loading..." : label}
      {icon && <ButtonIcon>{icon}</ButtonIcon>}
    </StyledButton>
  );
};

PrimaryButton.defaultProps = defaultProps;
PrimaryButton.propTypes = propTypes;

export const SecondaryButton = ({
  label,
  loading,
  disabled,
  icon,
  ...props
}) => {
  return (
    <StyledSecondaryButton disabled={disabled} {...props}>
      {loading ? "Loading..." : label}
      {icon && <ButtonIcon style={{ paddingLeft: "8px" }}>{icon}</ButtonIcon>}
    </StyledSecondaryButton>
  );
};

SecondaryButton.propTypes = propTypes;

export const AlternativeButton = ({
  label,
  loading,
  disabled,
  icon,
  ...props
}) => {
  return (
    <StyledAlternativeButton disabled={disabled} {...props}>
      {icon && <ButtonIcon style={{ paddingRight: "8px" }}>{icon}</ButtonIcon>}
      {loading ? "Loading..." : label}
    </StyledAlternativeButton>
  );
};

AlternativeButton.defaultProps = defaultProps;
AlternativeButton.propTypes = propTypes;

export const LinkButton = ({ label, icon, ...props }) => {
  return (
    <StyledLinkButton {...props}>
      {icon && <>{icon}</>}
      {label}
    </StyledLinkButton>
  );
};

LinkButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node
};

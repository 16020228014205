const SidebarItems = [
  {
    name: "Products",
    route: "/admin"
  },
  {
    name: "Categories",
    route: "/categories"
  },
  {
    name: "Providers",
    route: "/providers"
  },
  {
    name: "Product Reviews",
    route: "/product-reviews"
  },
  {
    name: "Blog Comments",
    route: "/blog-comments"
  },
  {
    name: "Users",
    route: "/users"
  },
  {
    name: "Store Front",
    route: "/"
  }
];

export default SidebarItems;

import React, { useEffect } from "react";
import { firebaseConfig } from "../config/firebase";
import PropTypes from "prop-types";

const remoteConfig = firebaseConfig.remoteConfig();
// Only for development
// if (DEV_MODE)
//   remoteConfig.settings = {
//     minimumFetchIntervalMillis: 60000
//   };

remoteConfig.defaultConfig = {
  show_footer_categories: { enabled: false }
};

export const FlagsContext = React.createContext({});

export const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults);

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        if (!activated) return remoteConfig.getAll();
      })
      .then((remoteFlags) => {
        const newFlags = {
          ...flags
        };
        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config.asString();
        }
        setFlags(newFlags);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

FlagsProvider.propTypes = {
  defaults: PropTypes.object,
  children: PropTypes.element
};

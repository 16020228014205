import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { ChevronLeft } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import Markdown from "markdown-to-jsx";

import Queries from "../../graphql/queries";
import Mutations from "../../graphql/mutations";
import BlogComments from "./BlogComments";
import {
  Loading,
  TextArea,
  PrimaryButton,
  LinkButton,
  Layout,
  ErrorComponent,
  MetaDecorator
} from "../../components";

import { formatDate } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";

import {
  BlogContainer,
  BlogTitle,
  ExcerptText,
  CoverImageContainer,
  CoverImage,
  BlogDate,
  BlogContent,
  Attributes,
  TagContainer,
  TagWrapper,
  Tags,
  Separator,
  ButtonContainer,
  CommentHeader,
  BlogWrapper,
  BlogHeaderContainer,
  CommentSectionContainer,
  BlogHeightWrapper
} from "./Blog.styled";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

export default function BlogDetails() {
  useEffect(() => {
    analytics.logEvent(Log.screens.BLOG_DETAIL_VIEWED);
  }, []);
  const { slug } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [comment, setComment] = useState("");
  const isAuthenticated = user && user.user && user.user.name;
  const { loading, error, data } = useQuery(Queries.GET_POST, {
    variables: {
      slug: slug
    }
  });

  const [createComment, { loading: isAddingComment }] = useMutation(
    Mutations.CREATE_COMMENT,
    {
      update: (cache, result) => {
        const commentData = cache.readQuery({
          query: Queries.GET_COMMENTS,
          variables: {
            postId: data.post.id
          }
        });
        cache.writeQuery({
          query: Queries.GET_COMMENTS,
          variables: {
            postId: data.post.id
          },
          data: {
            comment: [...commentData.comment, result.data.createComment]
          }
        });
      }
    }
  );

  // save comment to state
  const handleInput = (event) => {
    setComment(event.target.value);
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      return history.push({
        pathname: "/auth",
        state: { from: location }
      });
    }
    await createComment({
      variables: {
        text: comment,
        postId: data.post.id
      }
    });
    setComment("");
  };

  const goBack = () => {
    history.goBack();
  };

  if (error) return <ErrorComponent error={error.message} />;

  if (!data && loading)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  return (
    <Layout>
      <MetaDecorator
        title={data.post.title}
        description={data.post.excerpt}
        imageUrl={data.post.coverImage.url}
        imageAlt={data.post.title}
      />
      <BlogHeightWrapper>
        <BlogContainer style={{ minHeight: "unset" }}>
          <BlogWrapper style={{ maxWidth: "920px" }}>
            <BlogHeaderContainer>
              <>
                <LinkButton
                  onClick={goBack}
                  label="All Articles"
                  icon={<ChevronLeft size={16} />}
                />
              </>
              <BlogTitle>{data?.post?.title}</BlogTitle>
              <ExcerptText>{data?.post?.excerpt}</ExcerptText>
              <Attributes>
                <BlogDate>
                  Published on {formatDate(data.post.publishedAt)}{" "}
                </BlogDate>
                <Separator>•</Separator>
                <BlogDate>
                  By {data?.post?.author?.name || "Snkr Mart"}
                </BlogDate>
              </Attributes>
            </BlogHeaderContainer>
          </BlogWrapper>
          <CoverImageContainer>
            <CoverImage src={data?.post?.coverImage?.url} />
          </CoverImageContainer>
          <BlogWrapper>
            <BlogContent>
              <Markdown options={{ forceBlock: true }}>
                {data.post.content.markdown}
              </Markdown>
            </BlogContent>

            <TagContainer>
              <Tags>Tags: </Tags>
              {data.post.tags.map((tag) => (
                <TagWrapper key={tag}>
                  <Tags> {tag} </Tags>
                </TagWrapper>
              ))}
            </TagContainer>
          </BlogWrapper>
        </BlogContainer>
        <BlogWrapper>
          <CommentSectionContainer>
            <CommentHeader>Leave a Comment</CommentHeader>
            <TextArea
              onChange={handleInput}
              placeholder="What do you think?"
              value={comment}
              disabled={!isAuthenticated}
            ></TextArea>
            <>
              <ButtonContainer>
                <PrimaryButton
                  onClick={handleSubmitComment}
                  label={
                    isAuthenticated ? "Post Comment" : "Sign in to comment"
                  }
                  loading={isAddingComment}
                  disabled={isAuthenticated && !comment.trim()}
                />
              </ButtonContainer>
            </>
            <BlogComments postId={data.post.id} />
          </CommentSectionContainer>
        </BlogWrapper>
      </BlogHeightWrapper>
    </Layout>
  );
}

import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

import Queries from "../../../../graphql/queries";
import { BlogCard, ErrorComponent, Loading } from "../../../../components";

import {
  SectionContainer,
  SectionWrapper,
  TopSection,
  StyledSubtitle1,
  TitleWrapper,
  ItemsSection,
  StyledBody3,
  Divider
} from "../SectionStyles.styled";

export default function Blog() {
  const { loading, data, error } = useQuery(Queries.GET_POSTS);
  if (!data && loading) return <Loading />;
  if (error) return <ErrorComponent error={error.message} />;

  return (
    <SectionContainer>
      <TopSection>
        <TitleWrapper>
          <StyledSubtitle1>I don&apos;t do too much blogging</StyledSubtitle1>
        </TitleWrapper>
        <SectionWrapper>
          <Link to={"/blog"}>
            <StyledBody3>View more</StyledBody3>
          </Link>
        </SectionWrapper>
      </TopSection>
      <FlexGrid
        flexGridColumnCount={[1, 1, 2, 3]}
        flexGridColumnGap="scale900"
        flexGridRowGap="scale700"
      >
        {data.posts.slice(0, 3).map((post) => {
          const {
            url = "https://media.graphcms.com/output=format:jpg/k6o8wBkZSIeqBrhZk450"
          } = post.coverImage;
          const {
            name = "Snkr Mart",
            picture = {
              url: "https://media.graphcms.com/k6o8wBkZSIeqBrhZk450"
            }
          } = { ...post.author };
          return (
            <FlexGridItem key={post.id}>
              <ItemsSection>
                <Link
                  label="blog"
                  to={`/blog/${post.slug}/` || "/"}
                  key={post.slug}
                >
                  <BlogCard
                    coverImage={url}
                    blogTitle={post?.title}
                    excerpt={post.excerpt}
                    authorAvatar={picture?.url}
                    authorName={name}
                    publishedDate={post?.publishedAt}
                    tags={post?.tags || []}
                  />
                </Link>
              </ItemsSection>
            </FlexGridItem>
          );
        })}
      </FlexGrid>
      <Divider />
    </SectionContainer>
  );
}

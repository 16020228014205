import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Sidebar from "./SideBar";
import Nav from "./Nav";

import { whiteShade } from "../../../utils";
import { useAuth } from "../../../contexts/AuthContext";

const LayoutContainer = styled.div`
  display: flex;
`;

const LayoutWrapper = styled.div`
  width: 100%;
  background-color: ${whiteShade};
`;

export function AdminLayout(props) {
  const { user } = useAuth();
  const history = useHistory();
  const role = user && user.user && user.user.role;

  useEffect(() => {
    if (role !== "admin") {
      history.push("/");
    }
  }, []);

  return (
    <>
      <LayoutContainer>
        <Sidebar history={props.history} />
        <LayoutWrapper>
          <Nav />
          {props.children}
        </LayoutWrapper>
      </LayoutContainer>
    </>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object.isRequired
  }).isRequired
};

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Menu, Search, X } from "react-feather";
import { white } from "../../utils";

import MenuItems from "./Menu/MenuItems";
import Queries from "../../graphql/queries";
import { Drawer } from "baseui/drawer";
import { SearchBar } from "../TextFields/SearchBar";

const HeaderCard = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 56px;
    width: 100%;
    z-index: 10;
    background-color: ${white};
    border-bottom: 1px solid #f2f2f2;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
`;

const SnkrLogo = styled.img`
  height: 50px;
  width: 70px;
`;

const StartSection = styled.div`
  display: flex;
  justify-self: start;
  align-items: center;
`;

const IconButton = styled.button`
  cursor: pointer;
  z-index: 1;
  padding: 0;
  border: 0;
  background: transparent;
`;

const Icon = styled.span`
  font-size: 36px;
`;

const EndSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AccountSection = styled.div`
  margin-left: 1.5rem;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const xStyle = {
  marginLeft: "8px"
};

export const MobileHeader = () => {
  const [categories, setCategories] = useState([]);
  const { data } = useQuery(Queries.GET_CATEGORIES);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setCategories(data && data.categories);
    }
    return () => (mounted = false);
  }, [data]);
  const [open, setOpen] = useState(false);
  const [search, showSearch] = useState(false);

  const showSearchOnClick = () => {
    showSearch(!search);
  };

  const showMenuDrawer = () => {
    setOpen(!open);
  };

  return (
    <HeaderCard>
      <HeaderContainer>
        {!search && (
          <StartSection>
            <IconButton onClick={showMenuDrawer}>
              <Link label="SnkrMart logo" to="/">
                <SnkrLogo
                  alt="SnkrMart logo"
                  src={
                    "https://res.cloudinary.com/snkrmart/image/upload/v1625510718/SNRKMART_wsvza3.svg"
                  }
                />
              </Link>
            </IconButton>
          </StartSection>
        )}
        <>
          {search ? (
            <SearchContainer>
              <SearchBar autoFocus={true} />
              <IconButton style={xStyle} onClick={showSearchOnClick}>
                <Icon>
                  <X color="black" />
                </Icon>
              </IconButton>
            </SearchContainer>
          ) : (
            <>
              {!search && (
                <EndSection>
                  <IconButton onClick={showSearchOnClick}>
                    <Icon>
                      <Search color="black" />
                    </Icon>
                  </IconButton>
                  <AccountSection>
                    <IconButton onClick={showMenuDrawer}>
                      <Icon>
                        {open ? <X color="black" /> : <Menu color="black" />}
                      </Icon>
                    </IconButton>
                  </AccountSection>
                </EndSection>
              )}
            </>
          )}
        </>
      </HeaderContainer>
      <Drawer
        size={"80%"}
        isOpen={open}
        anchor={"right"}
        onClose={showMenuDrawer}
        overrides={{
          Root: {
            style: () => ({
              zIndex: "10"
            })
          },

          Close: {
            style: () => ({
              color: "#fff",
              fontSize: "24px"
            })
          }
        }}
      >
        <div>
          <MenuItems setOpen={setOpen} open={open} categories={categories} />
        </div>
      </Drawer>
    </HeaderCard>
  );
};

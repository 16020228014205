import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "react-feather";
import Slider from "react-slick";
import { Skeleton } from "baseui/skeleton";
import { BrowserView } from "react-device-detect";
import uuid from "react-uuid";

import Queries from "../../../../graphql/queries";
import {
  H1,
  Subtitle1,
  Body2,
  ProductCard,
  ErrorComponent,
  ProductCardSkeleton
} from "../../../../components";

import { average, secondaryRed } from "../../../../utils";

const ProductContainer = styled.div`
  margin-top: 48px;
  @media screen and (max-width: 720px) {
    margin-top: 16px;
  }
`;

const ProductWrapper = styled.div`
  margin-bottom: 32px;
`;

const TopSection = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 720px) {
    margin-bottom: 8px;
  }
`;

const LeftSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: :center;
`;

const ProductsSection = styled.div`
  margin-top: 16px;
`;

const ProductsSectionSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media screen and (max-width: 720px) {
    display: flex;
    gap: 1rem;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    gap: 0.3rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSubtitle1 = styled(Subtitle1)`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 26px;
  text-transform: none;
  vertical-align: baseline;
  opacity: 0.9;
  @media screen and (max-width: 720px) {
    font-weight: 600;
  }
`;

const StyledBody3 = styled(Body2)`
  font-weight: 600;
  cursor: pointer;
  color: ${secondaryRed};
  &:hover {
    text-decoration: underline;
  }
`;

export const UnderLine = styled.span`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    bottom: -10px;
    left: 0;
    background-color: rgb(224, 79, 0);
  }
`;

export const StyledH1 = styled(H1)`
  letter-spacing: -0.04em;
  font-weight: 700;
  margin: 16px 0;
  font-size: 36px;
`;

const ArrowButtons = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 1;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
  padding: 0;
`;

export const Divider = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eeeeee;
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    margin: 0 24px;
  }

  // Fix external margins
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slider {
    overflow: hidden;
  }
`;

function RightNavButton(props) {
  const { /* className, */ style, onClick } = props;
  return (
    <ArrowButtons
      className="slick-arrow"
      style={{ ...style, right: "-10px" }}
      onClick={onClick}
    >
      <>
        <ChevronRight color="black" size={16} />
      </>
    </ArrowButtons>
  );
}

function LeftNavButton(props) {
  const { /* className, */ style, onClick } = props;
  return (
    <ArrowButtons
      className="slick-arrow"
      style={{ ...style, display: "block", left: "-10px" }}
      onClick={onClick}
    >
      <>
        <ChevronLeft color="black" size={16} />
      </>
    </ArrowButtons>
  );
}

const settings = {
  className: "slider variable-width",
  infinite: true,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  adaptiveHeight: true,
  variableWidth: true,
  nextArrow: <RightNavButton />,
  prevArrow: <LeftNavButton />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        swipeToSlide: true,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

const overrides = {
  Root: {
    style: () => ({
      borderRadius: "10px"
    })
  }
};

const LoadingSkeleton = () => {
  return (
    <>
      <BrowserView>
        <Skeleton height="25px" width="300px" overrides={overrides} />
      </BrowserView>
      <ProductWrapper>
        <ProductsSectionSkeleton>
          {Array.apply(null, Array(6)).map(() => {
            return <ProductCardSkeleton key={uuid()} />;
          })}
        </ProductsSectionSkeleton>
      </ProductWrapper>
    </>
  );
};

function ProductsSlider({ products }) {
  return (
    <StyledSlider {...settings}>
      {products?.products.map((product) => {
        const { id, imageUrl, name, price, provider, reviews } = product;
        const rating = reviews?.map((review) => parseInt(review.rating));
        return (
          <div key={id}>
            <ProductCard
              imageUrl={imageUrl}
              title={name.toLowerCase()}
              provider={provider?.name}
              price={price}
              rating={average(rating)}
              providerId={provider?.id}
              productId={id}
              reviews={reviews.length}
            />
          </div>
        );
      })}
    </StyledSlider>
  );
}

export function FeaturedProducts() {
  const { loading, error, data } = useQuery(Queries.GET_PRODUCTS);
  if (error) return <ErrorComponent error={error.message} />;
  if (!data && loading)
    return (
      <ProductsSection>
        <ProductWrapper>
          <LoadingSkeleton />
        </ProductWrapper>
      </ProductsSection>
    );

  return (
    <ProductsSection>
      <ProductWrapper>
        <TopSection>
          <TitleWrapper>
            <StyledSubtitle1>Featured Products</StyledSubtitle1>
          </TitleWrapper>
        </TopSection>
        <StyledSlider {...settings}>
          {data &&
            data.products
              .filter((filteredProducts) => filteredProducts.featured === true)
              .map((product) => {
                const {
                  id,
                  imageUrl,
                  name,
                  price,
                  provider,
                  reviews
                } = product;

                const rating = reviews?.map((review) =>
                  parseInt(review.rating)
                );
                return (
                  <div key={id}>
                    <ProductCard
                      key={id}
                      imageUrl={imageUrl}
                      title={name.toLowerCase()}
                      provider={provider?.name}
                      price={price}
                      productId={id}
                      providerId={provider?.id}
                      rating={average(rating)}
                      reviews={reviews.length}
                    />
                  </div>
                );
              })}
        </StyledSlider>
      </ProductWrapper>
      <Divider />
    </ProductsSection>
  );
}

export function Products() {
  const { loading, error, data } = useQuery(Queries.GET_CATEGORIES);

  if (error) return <ErrorComponent error={error.message} />;
  if (!data && loading)
    return (
      <ProductContainer>
        <LoadingSkeleton />
      </ProductContainer>
    );

  return (
    <ProductContainer>
      {data.categories &&
        data.categories
          .filter((category) => category.products.length > 0)
          .map((filteredCategory) => {
            return (
              <div key={filteredCategory.id}>
                <ProductWrapper key={filteredCategory.id}>
                  <TopSection>
                    <TitleWrapper>
                      <StyledSubtitle1>{filteredCategory.name}</StyledSubtitle1>
                    </TitleWrapper>
                    <LeftSectionContainer>
                      <Link
                        to={`/category/${filteredCategory.id}`}
                        key={filteredCategory.id}
                      >
                        <StyledBody3>View more</StyledBody3>
                      </Link>
                      <ChevronRight color={secondaryRed} size={16} />
                    </LeftSectionContainer>
                  </TopSection>
                  <ProductsSection>
                    <ProductsSlider products={filteredCategory} />
                  </ProductsSection>
                </ProductWrapper>
                <Divider />
              </div>
            );
          })}
    </ProductContainer>
  );
}

ProductsSlider.propTypes = {
  products: PropTypes.object
};

RightNavButton.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

LeftNavButton.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";

import {
  ProductCard,
  Layout,
  EmptyState,
  ProductCardSkeleton,
  Subtitle3
} from "../components";

const GET_PRODUCTS = gql`
  query products {
    products: getProducts {
      id
      name
      price
      imageUrl
      provider {
        id
        name
      }
      category {
        id
        name
        products {
          id
          name
        }
      }
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  min-height: 60vh;
  margin: 0 auto;
  padding: 0 40px 40px 40px;
  transition: all 0.25s ease-out;
  @media screen and (max-width: 720px) {
    margin: 0;
    justify-content: center;
    padding: 0 16px 16px 16px;
  }
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 32px;
  /* margin: 48px 0; */
  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
  }
`;

const ProductTitle = styled(Subtitle3)`
  margin: 25px 0px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

const IframeContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export default function Products() {
  const url = new URL(window.location.href);
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  if (error) return `Error! ${error.message}`;

  let products;
  if (url.search) {
    const urlParams = queryString.parse(url.search);
    products = data?.products.filter((product) =>
      product.name.toLowerCase().match(urlParams.params.toLowerCase())
    );
  } else {
    products = data.products;
  }

  return (
    <Layout>
      <ProductContainer>
        <ProductTitle>Search Results:</ProductTitle>
        {!data && loading ? (
          <>
            <ProductWrapper>
              {Array.apply(null, Array(5)).map((i) => (
                <ProductCardSkeleton key={i} />
              ))}
            </ProductWrapper>
          </>
        ) : (
          <ProductWrapper>
            {products.length > 0 ? (
              products.map((product) => {
                const { id, imageUrl, name, price, provider } = product;
                return (
                  <Link label="products" to={`/product/${id}`} key={id}>
                    <ProductCard
                      key={id}
                      imageUrl={imageUrl}
                      title={name.toLowerCase()}
                      provider={provider?.name}
                      price={price}
                    />
                  </Link>
                );
              })
            ) : (
              <IframeContainer>
                <EmptyStateContainer>
                  <EmptyState
                    firstTitle={"Sorry, your search did not match any products"}
                  />
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLScJAugdN86PR1VhtItJjxFlsy-liHIgVEPHFxGOCjmqokOwnQ/viewform?embedded=true"
                    width="auto"
                    height="478"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                  >
                    Loading…
                  </iframe>
                </EmptyStateContainer>
              </IframeContainer>
            )}
          </ProductWrapper>
        )}
      </ProductContainer>
    </Layout>
  );
}

const socialAuth = async (fn, response, action, provider) => {
  const { token, profile, error, status } = response;

  if (error || status) {
    return {
      message: "Dialog closed.",
      status: 450
    };
  }

  const { email, name, imageUrl } = profile;

  const result = await fn({
    variables: {
      token,
      name,
      email,
      imageUrl,
      action,
      provider
    }
  });

  if (result && result.data && result.data.socialAuth !== null) {
    const {
      message,
      status,
      access_token,
      refresh_token,
      user
    } = result.data.socialAuth;

    return { message, status, access_token, refresh_token, user };
  }

  return { errors: result.errors };
};

const saveAuthCredentials = (
  access_token,
  refresh_token,
  user,
  updateTokens,
  updateUser
) => {
  window.localStorage.setItem("access_token", access_token);
  window.localStorage.setItem("refresh_token", refresh_token);
  window.localStorage.setItem("user", JSON.stringify(user));

  updateTokens({
    accessToken: access_token,
    refreshToken: refresh_token
  });

  updateUser({ user });
};

const handleAuth = async (
  fn,
  response,
  action,
  provider,
  updateTokens,
  updateUser
) => {
  const {
    message,
    status,
    access_token,
    refresh_token,
    user,
    errors
  } = await socialAuth(fn, response, action, provider);

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (status === 201) {
    // Save tokens
    saveAuthCredentials(
      access_token,
      refresh_token,
      user,
      updateTokens,
      updateUser
    );
  }

  return {
    status,
    message
  };
};

const registerUser = async (
  register,
  regInputs,
  errorMsg,
  updateTokens,
  updateUser
) => {
  const { name, email, password, password_confirmation } = regInputs;
  const data = await register({
    variables: {
      name,
      email,
      password,
      password_confirmation
    }
  });

  if (data && data.data && data.data.signupUser) {
    const {
      access_token,
      message,
      refresh_token,
      status,
      user
    } = data.data.signupUser;

    if (status === 201) {
      saveAuthCredentials(
        access_token,
        refresh_token,
        user,
        updateTokens,
        updateUser
      );

      return {
        message,
        status
      };
    } else {
      throw new Error(errorMsg);
    }
  } else {
    throw new Error(errorMsg);
  }
};

const loginUser = async (
  login,
  loginInputs,
  errorMsg,
  updateTokens,
  updateUser
) => {
  const { email, password } = loginInputs;
  const data = await login({
    variables: {
      email,
      password
    }
  });

  if (data && data.data && data.data.loginUser) {
    const {
      access_token,
      message,
      refresh_token,
      status,
      user
    } = data.data.loginUser;

    if (status === 201) {
      saveAuthCredentials(
        access_token,
        refresh_token,
        user,
        updateTokens,
        updateUser
      );

      return {
        message,
        status
      };
    } else {
      throw new Error(errorMsg);
    }
  } else {
    throw new Error(errorMsg);
  }
};

const getUser = () => window.localStorage.getItem("user");
const getAccessToken = () => window.localStorage.getItem("access_token");
const getRefreshToken = () => window.localStorage.getItem("refresh_token");
const clearStorage = () => {
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("user");
};

export default {
  registerUser,
  loginUser,
  socialAuth,
  saveAuthCredentials,
  handleAuth,
  getUser,
  getAccessToken,
  getRefreshToken,
  clearStorage
};

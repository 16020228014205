import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Skeleton } from "baseui/skeleton";
import { ChevronLeft, ChevronRight } from "react-feather";
import Queries from "../../../../graphql/queries";
import { ErrorComponent } from "../../../../components";
import { whiteShade } from "../../../../utils";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${whiteShade};
  width: 100%;
  margin: 0 auto;
  height: 55vh;
  /* overflow-x: hidden; */
  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    margin-top: 3.2rem;
  }
`;

const EachFade = styled.div`
  background-size: cover;

  height: 55vh;
  width: 100%;
  @media screen and (max-width: 720px) {
    height: 50vh;
  }
`;

const FadeImage = styled.img`
  width: 100%;
  height: 55vh;
  object-fit: cover;
  background: no-repeat center top;
`;

const ArrowButtons = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 1;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
  padding: 0;
`;

function RightNavButton(props) {
  const { /* className, */ style, onClick } = props;
  return (
    <ArrowButtons
      className="slick-arrow"
      style={{ ...style, right: "10px" }}
      onClick={onClick}
    >
      <>
        <ChevronRight color="black" size={24} />
      </>
    </ArrowButtons>
  );
}

function LeftNavButton(props) {
  const { /* className, */ style, onClick } = props;
  return (
    <ArrowButtons
      className="slick-arrow"
      style={{ ...style, display: "block", left: "10px" }}
      onClick={onClick}
    >
      <>
        <ChevronLeft color="black" size={24} />
      </>
    </ArrowButtons>
  );
}

const settings = {
  infinite: true,
  autoplay: true,
  dots: true,
  arrows: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  // adaptiveHeight: true,
  nextArrow: <RightNavButton />,
  prevArrow: <LeftNavButton />
};

const HeroSlider = () => {
  const { loading, error, data } = useQuery(Queries.GET_HERO_IMAGES, {
    variables: {
      environment: APP_ENV,
      stage: "PUBLISHED"
    }
  });

  if (error) return <ErrorComponent error={error.message} />;

  const heroImages = (data && data.heroImages.edges) || [];

  return (
    <HeroContainer>
      <Slider {...settings}>
        {!data && loading && <Skeleton animation height="55vh" width="100%" />}
        {heroImages &&
          heroImages.map((i) => {
            const { heroLink, image } = i.node;
            return (
              <div key={image}>
                <EachFade>
                  <Link
                    to={{ pathname: heroLink || "/" }}
                    target={heroLink ? "_blank" : "_self"}
                  >
                    <FadeImage src={image.url} />
                  </Link>
                </EachFade>
              </div>
            );
          })}
      </Slider>
    </HeroContainer>
  );
};
export default HeroSlider;

RightNavButton.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

LeftNavButton.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import AuthUtils from "../../../utils/Auth";
import { useAuth } from "../../../contexts/AuthContext";
import SidebarItems from "./SidebarItems";
import Logo from "../icons/SNRKMART.svg";
import {
  whiteShade,
  primaryBlack,
  white,
  lightGray,
  secondaryRed
} from "../../../utils";

const SidebarParent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${white};
  width: 250px;
  height: 100vh;
  box-shadow: 0 12px 0px 0 rgb(0 0 0 / 8%);
  border-right: 1px solid ${lightGray};
  @media screen and (max-width: 1330px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  position: fixed;
  margin-top: 4rem;
  padding: 1rem 1em;
  height: 100%;
`;
const HeaderLogo = styled.img`
  height: 50px;
  padding-left: 2rem;
  padding-top: 1rem;
`;

const SidebarItem = styled.div`
  transition: all 0.25s ease-out;
  background: ${(props) => (props.active ? whiteShade : "")};
  border-left: ${(props) =>
    props.active ? `4px solid ${primaryBlack}` : "none"};
  border-radius: 0rem 1rem 1rem 0rem;
  margin: 1rem;
  p {
    padding: 12px 16px;
    color: ${primaryBlack};
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover:not(:first-child) {
    background: ${whiteShade};
  }
`;

const LogoutButton = styled.div`
  margin: 1rem;
  p {
    padding: 12px 16px;
    color: ${primaryBlack};
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: ${secondaryRed};
  }
  &:hover {
    cursor: pointer;
  }
`;

function Sidebar(props, { defaultActive }) {
  const { user, updateUser, updateTokens } = useAuth();
  const history = useHistory();
  const isAuthenticated = user && user.user && user.user.name;
  const location = props.history.location;
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
  const lastActiveIndex = Number(lastActiveIndexString);
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  );
  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }
  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem);
  }, [location]);

  const handleLogout = (event) => {
    if (isAuthenticated) {
      event.preventDefault();
      AuthUtils.clearStorage();
      updateTokens({});
      updateUser({});

      history.push("/");
    }
  };

  return (
    <>
      <SidebarParent>
        <Link to="/">
          <HeaderLogo src={Logo} />
        </Link>
        <SidebarContainer>
          {SidebarItems.map((item, index) => {
            return (
              <Link to={item.route} key={index}>
                <SidebarItem key={item.name} active={index === activeIndex}>
                  <p>{item.name}</p>
                </SidebarItem>
              </Link>
            );
          })}
          <LogoutButton onClick={handleLogout}>
            <p>Logout</p>
          </LogoutButton>
        </SidebarContainer>
      </SidebarParent>
    </>
  );
}
Sidebar.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object.isRequired
  }).isRequired
};
export default Sidebar;

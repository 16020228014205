import firebase from "firebase/app";

import "firebase/analytics";
import "firebase/remote-config";

const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGE_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

let firebaseConfig;

if (!firebase.apps.length) {
  firebaseConfig = firebase.initializeApp(config);
} else {
  firebaseConfig = firebase.app();
}
const analytics = firebaseConfig.analytics();

export { firebaseConfig, analytics };

import styled from "styled-components";
import { primaryBlack, mediumGray } from "../../utils";

import { Body2, Subtitle1 } from "../Heading/Heading";

const BaseStyles = `
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
`;

export const DisplayContainer = styled.div`
  ${BaseStyles}
  padding: 8rem 0;
  @media screen and (max-width: 720px) {
    padding: 8rem 16px;
  }
`;

export const EmptyStateContainer = styled.div`
  ${BaseStyles}
  padding: 1rem 0;
`;

export const DisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 12px;
`;

export const ErrorImage = styled.img``;

export const StyledH1 = styled(Subtitle1)`
  color: ${primaryBlack};
  margin-bottom: 8px;
  text-align: center;
`;

export const StyledH3 = styled(Body2)`
  color: ${mediumGray};
  text-align: center;
  padding: 0 16px;
  margin-bottom: 12px;
`;

import { createGlobalStyle } from "styled-components";
import { primaryBlack, white } from "./colors";

export const GlobalStyle = createGlobalStyle`


html {
  box-sizing: border-box;
  font-size: 16px;
}
*, *:before, *:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
font-weight:600;
}
p {
  font-family: 'Work Sans', sans-serif;
  font-size:16px;
  font-weight: 400;
  line-height: 30px;
  white-space: normal;

  @media screen and (max-width: 720px) {
    line-height: 27px;
    text-align: left;
    font-size:16px;
  }
}

li {
  font-family: 'Work Sans', sans-serif;
  line-height: 32px;
}
ul {
  font-family: 'Work Sans', sans-serif;
}
a {
  font-family: 'Work Sans', sans-serif;
  text-decoration: none;
  color:${primaryBlack}
}

body.no-scroll,
html.no-scroll {
  overflow-y: hidden;
}


body {
  display:flex;
  flex-direction:column;
  margin: 0;
  padding: 0;
    font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:${white};
  text-rendering: optimizeLegibility;
}
main {
  width: 100%;
}
`;

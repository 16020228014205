import styled from "styled-components";
import { H1, Body2 } from "../../components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding: 40px;
  margin: 0 auto;
  @media screen and (max-width: 720px) {
    padding: 40px 16px;
    margin: 0;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: 40px;
  margin: 0 auto;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 8%);
  width: 100%;
  @media screen and (max-width: 720px) {
    max-width: 100%;
    padding: 0;
    box-shadow:unset;
`;

export const PageTitle = styled(H1)`
  font-size: 32px;
  font-weight: 600;
`;

export const LegalDate = styled(Body2)`
  margin-top: 0.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

import { Skeleton } from "baseui/skeleton";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import {
  checkPrice,
  lightGray,
  mediumGray,
  white,
  secondaryRed
} from "../../utils";
import { Body1, Body2 } from "../Heading/Heading";
// import { Rate } from "../Rating/Rating";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.large ? "197px" : "157px")};
  margin: 0 16px;
  padding: 16px 0;
  background-color: ${white};
  cursor: pointer;
  transition: all 250ms ease-in-out;
  border-bottom: ${(props) =>
    props.showBorder ? `1px solid ${lightGray}` : ""};
  /* box-shadow: 0px 3px 8px -4px rgb(0 0 0 / 16%); */
  @media screen and (max-width: 720px) {
    width: 165px;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
`;

const ProductImageContainer = styled.div`
  width: ${(props) => (props.large ? "197px" : "157px")};
  height: ${(props) => (props.large ? "197px" : "157px")};
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  align-self: center;
  margin-bottom: 16px;
  @media screen and (max-width: 720px) {
    padding: 24px;
    width: 165px;
    height: 165px;
  }
`;

const ProductImage = styled.img`
  max-width: 100%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: transparent;
  @media screen and (max-width: 720px) {
    height: 150px;
  }
`;

const ProductTitle = styled(Body1)`
  font-weight: 500;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &:hover {
    color: ${secondaryRed};
  }
  @media screen and (max-width: 720px) {
    height: 50px;
    line-height: 16px;
  }
`;

const ProductTitleSkeleton = styled.div`
  margin-bottom: 8px;
  height: 40px;

  @media screen and (max-width: 720px) {
    height: 50px;
  }
`;

const ProductProvider = styled(Body2)`
  font-weight: 500;
  color: ${mediumGray};
  &:hover {
    color: ${secondaryRed};
  }
  @media screen and (max-width: 720px) {
    font-size: 0.75rem;
  }
`;

const ProductPrice = styled(Body2)`
  text-transform: capitalize;
  font-weight: 600;
  @media screen and (max-width: 720px) {
    font-size: 1.125rem;
  }
`;

const ProductPriceSkeleton = styled.div`
  min-height: 24px;
  @media screen and (max-width: 720px) {
    font-size: 1.125rem;
    height: 30px;
  }
`;

const WrapperSkeleton = styled.div`
  /* padding: 16px 16px; */
`;

// removing/hiding the reviews and ratings on the homepage until we build up provider liquidity

// const ProductRatingWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: 24px;
//   @media screen and (max-width: 720px) {
//     height: 30px;
//   }
// `;

// const ProductRating = styled(Body1)`
//   font-size: 12px;
//   margin-left: 4px;
// `;

const overrides = {
  Root: {
    style: () => ({
      borderRadius: "10px"
    })
  }
};

const Placeholder =
  "https://res.cloudinary.com/snkrmart/image/upload/v1629625852/path14_f6cz44.png";

export const ProductCard = ({
  imageUrl,
  title,
  provider,
  price,
  // rating,
  providerId,
  productId,
  // reviews,
  ...props
}) => {
  return (
    <CardContainer {...props}>
      <Link to={`/product/${productId}`}>
        <ProductImageContainer {...props}>
          <ProductImage
            alt={title}
            src={imageUrl === null ? Placeholder : imageUrl}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = Placeholder;
            }}
          />
        </ProductImageContainer>
        <ProductTitle>{title}</ProductTitle>
      </Link>
      <Link to={`/provider/${providerId}`} key={providerId}>
        <ProductProvider>{provider ? provider : ""}</ProductProvider>
      </Link>
      <ProductPrice>{checkPrice(price)}</ProductPrice>
      {/* <ProductRatingWrapper>
        <Rate readOnly size={12} value={rating} numItems={1} />
        <ProductRating>{`(${reviews})`}</ProductRating>
      </ProductRatingWrapper> */}
    </CardContainer>
  );
};

export const ProductCardSkeleton = ({ ...props }) => {
  return (
    <CardContainer {...props}>
      <ProductImageContainer {...props} style={{ padding: "0px" }}>
        <Skeleton height="100%" width="100%" animation overrides={overrides} />
      </ProductImageContainer>
      <WrapperSkeleton>
        <>
          <div style={{ marginBottom: "4px" }}>
            <Skeleton
              height="10px"
              width="30%"
              animation
              overrides={overrides}
            />
          </div>
        </>
        <ProductTitleSkeleton>
          <Skeleton
            height="10px"
            width="100%"
            animation
            overrides={overrides}
          />
        </ProductTitleSkeleton>
        <ProductPriceSkeleton>
          <Skeleton height="10px" width="40%" animation overrides={overrides} />
        </ProductPriceSkeleton>
      </WrapperSkeleton>
    </CardContainer>
  );
};

ProductCard.propTypes = {
  /**
   URL of the product image
    */
  imageUrl: PropTypes.string,
  /**
    The name of the product
    */
  title: PropTypes.string,

  /**
    The name of the product provider
    */
  provider: PropTypes.string,
  /**
    Product Price
    */
  price: PropTypes.string,
  reviews: PropTypes.number,
  rating: PropTypes.number,
  providerId: PropTypes.string,
  productId: PropTypes.string
};

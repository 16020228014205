import styled from "styled-components";
import { Body1, H1, Body3, Body2 } from "../../components";
import { darkGray } from "../../utils/colors";

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 40px;
  min-height: 100vh;
  @media screen and (max-width: 720px) {
    padding: 40px 16px;
    margin: 0;
  }
`;

export const CategoryWrapper = styled.div``;

export const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 32px;
  @media screen and (max-width: 1312px) {
    grid-template-columns: repeat(4, auto);
    grid-gap: 16px;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, auto);
    grid-gap: 16px;
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 16px;
  }
`;

export const BreadcrumbContainer = styled.div`
  margin-bottom: 16px;
`;

export const UnderLine = styled.span`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    bottom: -18px;
    left: 0;
    background-color: rgb(224, 79, 0);
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 16px;
  background-color: #f8f8f8;
  @media screen and (max-width: 720px) {
    width: 180px;
  }
`;

export const CategoryImageContainer = styled.div`
  width: 180px;
  height: 220px;
  border-radius: 10px;
  margin-bottom: 16px;
  @media screen and (max-width: 720px) {
    padding: 24px;
    height: 180px;
  }
`;

export const CategoryImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
`;

export const CategoryName = styled(Body1)`
  font-weight: 500;
  text-align: left;
  margin-bottom: 4px;
`;

export const CategoryDescription = styled(Body3)`
  font-weight: 500;
  text-align: left;
  opacity: 0.7;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 3rem 0 2rem 0;
  border-bottom: 1px solid #cbcbcb;
`;

export const StyledH1 = styled(H1)`
  letter-spacing: -0.04em;
  font-weight: 700;
  font-size: 48px;
  @media screen and (max-width: 720px) {
    font-size: 22px;
  }
`;

export const StyledP = styled(Body2)`
  width: 920px;
  margin-top: 12px;
  color: ${darkGray};
  @media screen and (max-width: 720px) {
    width: 100%;
    font-size: 12px;
  }
`;
export const TotalText = styled(Body1)`
  opacity: 0.7;
  margin-bottom: 4px;
`;

export const EmptyStateText = styled(Body3)`
  margin-top: 8px;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

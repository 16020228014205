import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mediumGray, formatDate } from "../../utils";
import { Body2, Subtitle2 } from "../Heading/Heading";

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  cursor: pointer;
  flex: 1 1 250px;
  &:hover {
  }
  @media screen and (max-width: 720px) {
    flex-direction: column;
    /* padding: 0px 10px 0 10px; */
  }
`;

const CoverImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 210px;
  @media screen and (max-width: 720px) {
    width: 100%;
    height: 228px;
  }
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f9f9f9;
  border-radius: 4px;
  &:hover {
    object-fit: cover;
    transition: all 400ms ease-in-out 0s;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    margin-left: 0px;
  }
`;

const BlogTitle = styled(Subtitle2)`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 720px) {
    width: 350px;
  }
`;

const BlogExcerpt = styled(Body2)`
  margin: 0 0 16px 0;
  font-weight: 400;
  color: #575757;
  max-width: 450px;
  @media screen and (max-width: 720px) {
    max-width: 400px;
    margin: 0 0 16px 0;
  }
`;

const DateContainer = styled.div`
  margin: 8px 0;
  @media screen and (max-width: 720px) {
    margin-top: 10px;
  }
`;

const BlogDate = styled.h3`
  margin: 0;
  font-size: 12px;
  line-height: 130%;
  font-weight: 500;
  padding-top: 5px;
  color: ${mediumGray};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-align: flex-start;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
`;

const TagWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 8px;
  margin-bottom: 6px;
`;

const Tags = styled.h4`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #151515;
  margin: 0;
`;

export const BlogCard = ({
  coverImage,
  blogTitle,
  publishedDate,
  excerpt,
  tags
}) => {
  return (
    <BlogContainer>
      <CoverImageContainer>
        <CoverImage src={coverImage} />
      </CoverImageContainer>
      <RightContainer>
        <DateContainer>
          <BlogDate>{formatDate(publishedDate)}</BlogDate>
        </DateContainer>
        <BlogTitle> {blogTitle} </BlogTitle>
        <BlogExcerpt> {excerpt} </BlogExcerpt>
        <TagContainer>
          {tags &&
            tags.map((tag) => (
              <TagWrapper key={tag}>
                <Tags> {tag} </Tags>
              </TagWrapper>
            ))}
        </TagContainer>
      </RightContainer>
    </BlogContainer>
  );
};

BlogCard.propTypes = {
  coverImage: PropTypes.string,
  blogTitle: PropTypes.string,
  publishedDate: PropTypes.string,
  excerpt: PropTypes.string,
  tags: PropTypes.array
};

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Avatar } from "baseui/avatar";
import { Body1, Body2 } from "../Heading/Heading";
import { primaryBlack, white } from "../../utils";

import { Rate } from "../Rating/Rating";

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  border-radius: 4px;
  padding: 16px 0;
  background-color: ${white};
  @media screen and (max-width: 720px) {
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 8%);
    padding: 24px 16px;
    margin-bottom: 16px;
  }
`;
const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledReviewDate = styled(Body2)`
  font-weight: 400;
  color: ${primaryBlack};
  margin-left: 4px;
  text-transform: capitalize;
`;

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const StyledSubjectText = styled(Body1)`
  font-weight: 600;
  color: ${primaryBlack};
  white-space: pre-wrap;
  text-transform: capitalize;
  margin-right: 8px;
`;

const ReviewContent = styled.p`
  font-family: "Work Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 0;
  color: #383838;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  border-bottom: 1px solid #00000010;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const ReviewCard = ({
  name,
  reviewAddedDate,
  review,
  rating,
  subject
}) => {
  return (
    <>
      <ReviewContainer>
        <TopSection>
          <Avatar name={name} size="scale900" />
          <TopSectionWrapper>
            <RatingWrapper>
              <StyledSubjectText>{subject}</StyledSubjectText>
              <Rate readOnly size={14} value={rating} />
            </RatingWrapper>
            <>
              <UserInfoWrapper>
                <Body2>on</Body2>
                <StyledReviewDate>{reviewAddedDate}</StyledReviewDate>
              </UserInfoWrapper>
            </>
          </TopSectionWrapper>
        </TopSection>
        <ReviewContentWrapper>
          <ReviewContent>{review.trim()}</ReviewContent>
        </ReviewContentWrapper>
      </ReviewContainer>
      <Divider />
    </>
  );
};

ReviewCard.propTypes = {
  name: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  reviewAddedDate: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  handleActions: PropTypes.func
};

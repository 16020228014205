export const primaryBlack = "#000000";
export const primaryRed = "#CA4436";
export const secondaryRed = "#e04f00";

export const white = "#FFFFFF";
export const whiteShade = "#f5f5f5";
export const darkBlack = "#111";

export const gray = "#D0D0D0";
export const darkGray = "#919191";
export const mediumGray = "#828282;";
export const lightGray = "#eeeeee";
export const crispyGray = "#919496";

export const yellow = "#F9E3AC";
export const green = "#388a24";

import styled from "styled-components";
import { Subtitle1, Body2 } from "../../../components";

export const SectionContainer = styled.div``;

export const SectionWrapper = styled.div`
  margin-left: auto;
`;

export const ProductWrapper = styled.div`
  margin-bottom: 44px;
`;

export const TopSection = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
`;

export const StyledSubtitle1 = styled(Subtitle1)`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 28px;
  text-transform: none;
  vertical-align: baseline;
`;

export const StyledBody3 = styled(Body2)`
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemsSection = styled.div`
  margin-top: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 0 0 3rem 0;
  border-bottom: 1px solid #eeeeee;
`;

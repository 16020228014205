import { Button } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { EmptyState, ErrorComponent, ReviewCard } from "../../components";
import { useAuth } from "../../contexts/AuthContext";
import { abbreviateName, reviewDate } from "../../utils";
import {
  ButtonContainer,
  Divider,
  ProductRatesSkeleton,
  StyledTitle
} from "./Product.styled";

const ProductReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 3rem;
  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const EmptyStateContainer = styled.div``;

export default function ProductReview({
  reviewData,
  loadingReviews,
  reviewError
}) {
  const { user } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [visible, setVisible] = useState(4);
  const role = user && user.user && user.user.role;

  useEffect(() => {
    setReviews(reviewData && reviewData);
  }, [reviewData]);

  const loadMore = () => {
    setVisible((prev) => {
      return prev + 4;
    });
  };

  if (!reviewData && loadingReviews)
    return (
      <ProductRatesSkeleton>
        <Skeleton
          height="150px"
          width="540px"
          animation
          overrides={{
            Root: {
              style: () => ({
                borderRadius: "10px"
              })
            }
          }}
        />
      </ProductRatesSkeleton>
    );
  if (reviewError) return <ErrorComponent error={reviewError.message} />;
  return (
    <>
      <ProductReviewContainer>
        <Divider />
        <StyledTitle>{`${reviewData?.reviews?.length} Reviews`}</StyledTitle>
        {reviews && reviews.reviews && reviews.reviews.length > 0 ? (
          reviews.reviews.slice(0, visible).map((review) => {
            const {
              approved,
              rating,
              subject,
              createdAt,
              reviewBy,
              id
            } = review;
            return (
              <ReviewCard
                key={id}
                name={reviewBy.name}
                imageUrl={reviewBy.imageUrl}
                reviewerName={abbreviateName(reviewBy.name)}
                reviewAddedDate={reviewDate(createdAt)}
                subject={subject}
                review={review.review}
                rating={parseInt(rating)}
                approved={approved}
                role={role}
              ></ReviewCard>
            );
          })
        ) : (
          <EmptyStateContainer>
            <EmptyState
              firstTitle="No reviews yet"
              secondTitle="Be the first to rate this product"
            />
          </EmptyStateContainer>
        )}
        <ButtonContainer>
          {visible < reviewData?.reviews?.length && (
            <Button kind={"secondary"} onClick={loadMore}>
              More Reviews
            </Button>
          )}
        </ButtonContainer>
      </ProductReviewContainer>
    </>
  );
}

ProductReview.propTypes = {
  productId: PropTypes.string,
  loadingReviews: PropTypes.bool,
  reviewData: PropTypes.shape({
    reviews: PropTypes.array
  }),
  reviewError: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

export const MetaDecorator = ({
  title,
  description,
  imageUrl,
  imageAlt,
  twitterContent
}) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:url" content={`${SITE_URL}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image:alt" content={imageAlt} />
    <meta
      name="twitter:image"
      content={
        twitterContent ||
        "https://res.cloudinary.com/snkrmart/image/upload/v1622048996/Snkrmart.png"
      }
    />
    <meta name="twitter:site" content="@snkrmart" />
  </Helmet>
);

MetaDecorator.defaultProps = {
  imageUrl: "",
  imageAlt: "",
  twitterContent: ""
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  twitterContent: PropTypes.string
};

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import HeaderComponent from "./HeaderComponent";
import { MobileHeader } from "./MobileHeader";
import Footer from "./Footer";

const HeaderContainer = styled.div``;
const ChildrenContainer = styled.div`
  @media screen and (max-width: 720px) {
    margin-top: 3rem;
  }
`;

export const Layout = (props) => {
  return (
    <HeaderContainer>
      <HeaderComponent {...props} />
      <MobileHeader />
      <ChildrenContainer>{props.children}</ChildrenContainer>
      <Footer />
    </HeaderContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

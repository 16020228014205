import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Avatar } from "baseui/avatar";
import { Button, KIND } from "baseui/button";

import { Body2 } from "../Heading/Heading";
import { primaryBlack } from "../../utils";

const CommentWrapper = styled.div`
  display: flex;
  padding-left: 8px;
`;

const AvatarWrapper = styled.div`
  margin-right: 12px;
`;
const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  position: relative;
  flex: 1 1 auto;
`;

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding: 0 8px;
    color: #919191;
    font-size: 14px;
    line-height: 22px;
  }
`;

const AuthorName = styled(Body2)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${primaryBlack};
  text-transform: capitalize;
`;

const DateAndCommentStyled = `
font-weight: 500;
font-size: 12px;
line-height: 20px;
color: ${primaryBlack};
opacity: 0.7;
text-align: left;

`;

const StyledCommentText = styled(Body2)`
  ${DateAndCommentStyled}
`;

const StyledCommentDate = styled(Body2)`
  ${DateAndCommentStyled}
  margin-left: 4px;
`;

const CommentText = styled.p`
  white-space: pre-wrap;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  margin: 0;
  color: #383838;
`;
const ActionsWrapper = styled.div``;

const Divider = styled.div`
  width: 100%;
  min-width: 100%;
  margin: 8px 0;
`;

const ChildrenContainer = styled.div`
  margin: 8px 0 16px 0;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Comments = ({
  author,
  text,
  dateTime,
  children,
  open,
  setOpen
  // approved,
  // role,
  // handleActions
}) => {
  return (
    <CommentWrapper>
      <ContentWrapper>
        <UserContainer>
          <AvatarWrapper>
            <Avatar name={author} size="scale900" />
          </AvatarWrapper>
          <AuthorWrapper>
            <UserInfoWrapper>
              <AuthorName>{author}</AuthorName>
              <UserInfo>
                <StyledCommentText>commented</StyledCommentText>
                <StyledCommentDate>{dateTime}</StyledCommentDate>
              </UserInfo>
            </UserInfoWrapper>
            {/* <ActionWrapper>
              {role === "admin" && (
                <DropDown
                  items={[approved ? "Disapprove" : "Approve"]}
                  onOptionClicked={handleActions}
                >
                  <DotsHorizontalRounded size="25" title="more" />
                </DropDown>
              )}
            </ActionWrapper> */}
          </AuthorWrapper>
        </UserContainer>
        <CommentText>{text.trim()}</CommentText>
        {open ? (
          <>
            <ChildrenContainer>{children}</ChildrenContainer>
          </>
        ) : (
          <>
            <ActionsWrapper>
              {" "}
              <Button
                onClick={() => {
                  setOpen(!open);
                }}
                kind={KIND.tertiary}
                size={"compact"}
                overrides={{
                  BaseButton: {
                    style: () => ({
                      color: "#919191"
                    })
                  }
                }}
              >
                Reply
              </Button>
            </ActionsWrapper>
            <Divider />
          </>
        )}
      </ContentWrapper>
    </CommentWrapper>
  );
};

Comments.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func
  // approved: PropTypes.Boolean,
  // role: PropTypes.string,
  // handleActions: PropTypes.func
};

Comments.defaultProps = {
  children: null,
  imageUrl: null
};

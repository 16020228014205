import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { withStyle, useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Tag, KIND, VARIANT } from "baseui/tag";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell
} from "baseui/table";
import { Edit } from "react-feather";

import Queries from "../../../graphql/queries";
import { USER_COLUMNS } from "../Columns";
import UpdateUser from "./UpdateUser";

import {
  PageContainer,
  PageTitle,
  PageWrapper,
  HeaderSection
} from "../Admin.styled";
import { ErrorComponent, Loading } from "../../../components";

const CenteredBodyCell = withStyle(StyledCell, {
  display: "flex",
  paddingTop: "4px",
  minHeight: "80px",
  minWidth: "40px",
  alignItems: "center"
});

function Users() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [css] = useStyletron();

  const { loading, error, data } = useQuery(Queries.GET_USERS);
  useEffect(() => {
    setUsers(data && data.users);
  }, [data]);

  const handleUpdateUser = (user) => {
    setUser(user);
    setIsOpen(!isOpen);
  };

  if (!data && loading) return <Loading />;
  if (error) return <ErrorComponent error={error.message} />;
  return (
    <>
      <UpdateUser isOpen={isOpen} setIsOpen={setIsOpen} userObj={user} />
      <PageContainer>
        <HeaderSection>
          <PageTitle>{`Users (${users && users.length})`}</PageTitle>
        </HeaderSection>
        <PageWrapper>
          <div className={css({ height: "80vh" })}>
            <StyledTable>
              <StyledHead>
                {USER_COLUMNS.map((col, index) => (
                  <StyledHeadCell key={index}>{col}</StyledHeadCell>
                ))}
              </StyledHead>
              <StyledBody>
                {users?.length
                  ? users.map((user, index) => {
                      const striped = index % 2 === 0;
                      return (
                        <StyledRow key={index}>
                          <CenteredBodyCell $striped={striped}>
                            {user.name}
                          </CenteredBodyCell>
                          <CenteredBodyCell $striped={striped}>
                            {user.email}
                          </CenteredBodyCell>
                          <CenteredBodyCell $striped={striped}>
                            <Tag
                              closeable={false}
                              variant={VARIANT.solid}
                              kind={
                                user.role === "admin" ? KIND.green : KIND.orange
                              }
                            >
                              {user.role === "admin" ? "Admin" : "Basic"}
                            </Tag>
                          </CenteredBodyCell>
                          <CenteredBodyCell $striped={striped}>
                            {user.socialLogin ? "Yes" : "No"}
                          </CenteredBodyCell>
                          <CenteredBodyCell $striped={striped}>
                            <Button
                              startEnhancer={() => <Edit size="16" />}
                              kind="secondary"
                              shape="pill"
                              size="compact"
                              onClick={() => handleUpdateUser(user)}
                            >
                              Update
                            </Button>
                          </CenteredBodyCell>
                        </StyledRow>
                      );
                    })
                  : null}
              </StyledBody>
            </StyledTable>
          </div>
        </PageWrapper>
      </PageContainer>
    </>
  );
}

export default Users;

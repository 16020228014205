import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Instagram, Facebook, Twitter, Youtube } from "react-feather";

import { darkBlack, mediumGray, white } from "../../utils";

const FooterContainer = styled.footer`
  background: ${darkBlack};
  width: 100%;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 32px 40px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    margin: 0;
    /* max-width: 720px; */
  }
`;

const FooterLogo = styled.img`
  height: 35px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 16px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
  @media (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const FooterLink = styled(Link)`
  font-family: "Work Sans", sans-serif;
  color: ${mediumGray};
  margin-bottom: 16px;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  text-decoration: none;
  &:hover {
    color: ${white};
  }
`;

const Heading = styled.p`
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #222;
  margin: 1rem 0;
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: baseline;
  }
`;

const FooterBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: unset;
    margin-top: 12px;
  }
`;

const FooterBottomLink = styled(Link)`
  font-family: "Work Sans", sans-serif;
  color: ${mediumGray};
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 10px;
  text-decoration: none;
  text-align: left;
  margin-right: 16px;
  &:hover {
    color: ${white};
  }
  @media screen and (max-width: 720px) {
    font-size: 10px;
    margin-left: 0;
  }
`;

const FooterBottomH6 = styled.h6`
  font-family: "Work Sans", sans-serif;
  color: ${mediumGray};
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 10px;
  text-decoration: none;
  text-align: left;
  margin-right: 16px;
  @media screen and (max-width: 720px) {
    font-size: 10px;
    margin-left: 0;
  }
`;

const SocialLinks = styled.div`
  a {
    display: inline-block;
    margin-right: 16px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  @media screen and (max-width: 720px) {
    align-items: center;
    a {
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Row>
          <Column>
            <Heading>
              <FooterLogo
                alt="SnkrMart logo"
                src={
                  "https://res.cloudinary.com/snkrmart/image/upload/v1625510718/SNRKMART-white_tvutok.svg"
                }
              />
            </Heading>
          </Column>
          <Column>
            <Heading>About Us</Heading>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="Home"
              to={"/"}
            >
              Home
            </FooterLink>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="Our-story"
              to={"/our-story"}
            >
              Our Story
            </FooterLink>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="Blog"
              to={"/blog"}
            >
              Blog
            </FooterLink>
          </Column>
          <Column>
            <Heading>Categories</Heading>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="categories"
              to={"/all-categories"}
            >
              Sneakers
            </FooterLink>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="categories"
              to={"/all-categories"}
            >
              Storage Products
            </FooterLink>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="categories"
              to={"/all-categories"}
            >
              Cleaning Products
            </FooterLink>
            <FooterLink
              id="footerLinks"
              className="footerLinks"
              label="categories"
              to={"/all-categories"}
            >
              Carrying Cases
            </FooterLink>
          </Column>
          {/* TODO use config to show this */}
          {/* <Column>
            <Heading>Support</Heading>
            <FooterLink href="mailto:help@snkrmart.com">Help</FooterLink>
            <FooterLink href="mailto:feedback@snkrmart.com">
              Contact Us
            </FooterLink>
            <FooterLink href="mailto:contact@snkrmart.com">
              Send Feedback
            </FooterLink>
          </Column> */}
          <Column>
            <SocialLinks>
              <Heading>Social</Heading>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/snkrmart/"
              >
                <Instagram color="white" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/shopsnkrmart/"
              >
                <Facebook color="white" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/snkrmart"
              >
                <Twitter color="white" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.youtube.com/channel/UCU05GZ1XfFCur6Mn8zmeXCg"
              >
                <Youtube color="white" />
              </a>
            </SocialLinks>
          </Column>
        </Row>
        <Divider />
        <FooterBottom>
          <FooterBottomH6>
            Copyright © {new Date().getFullYear()} SNKRMART
          </FooterBottomH6>
          <FooterBottomRow>
            <FooterBottomLink
              id="footerLinks"
              className="footerLinks"
              label="Legal"
              to={"/privacy-policy"}
            >
              Privacy Policy
            </FooterBottomLink>

            <FooterBottomLink
              id="footerLinks"
              className="footerLinks"
              label="Legal"
              to={"/terms-of-service"}
            >
              Terms of Service
            </FooterBottomLink>

            <FooterBottomLink
              id="footerLinks"
              className="footerLinks"
              label="Legal"
              to={"/electronic-communications-policy"}
            >
              Electronic Communications Policy
            </FooterBottomLink>
            <FooterBottomLink
              id="footerLinks"
              className="footerLinks"
              label="Legal"
              to={"/anti-discrimination-policy"}
            >
              Anti-Discrimination Policy
            </FooterBottomLink>
          </FooterBottomRow>
        </FooterBottom>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;

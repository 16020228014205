import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { white } from "../../utils";
import { useOnClickOutside } from "../../hooks/hooks";

const DropDownContainer = styled("div")`
  position: relative;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  top: 10px;
  width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background-color: ${white};
  &:hover {
    cursor: pointer;
  }
`;

const DropDownList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 16px;
  text-align: left;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const DropDown = ({ items, onOptionClicked }) => {
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setIsOpen(false));

  const handleListClick = () => {
    onOptionClicked();
    setIsOpen(false);
  };

  return (
    <>
      <DropDownContainer ref={node}>
        <DropDownListContainer>
          <DropDownList>
            {items.map((item) => (
              <ListItem key={Math.random()} onClick={handleListClick}>
                {item}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      </DropDownContainer>
    </>
  );
};

DropDown.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOptionClicked: PropTypes.func.isRequired
};

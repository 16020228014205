import React from "react";
import PropTypes from "prop-types";
import {
  EmptyStateContainer,
  DisplayWrapper,
  ButtonContainer,
  StyledH1,
  StyledH3
} from "./Display.styled";
import { PrimaryButton } from "../Button/Buttons";

export const EmptyState = ({
  firstTitle,
  secondTitle,
  buttonAction,
  buttonLabel
}) => {
  return (
    <EmptyStateContainer>
      <DisplayWrapper>
        <StyledH1>{firstTitle}</StyledH1>
        <StyledH3>{secondTitle}</StyledH3>
        <ButtonContainer>
          {buttonAction && (
            <PrimaryButton onClick={buttonAction} label={buttonLabel} />
          )}
        </ButtonContainer>
      </DisplayWrapper>
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  firstTitle: PropTypes.string.isRequired,
  secondTitle: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonLabel: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { PrimaryButton } from "../Button/Buttons";

const ErrorContainer = styled.div`
  display: flex;
  margin: 0 auto;
  background-color: #f9fafa;
  height: 100vh;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 8px;
  text-align: center;
`;

const StyledMessageTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: normal;
`;

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <ErrorContainer>
      <ErrorWrapper>
        <StyledTitle>Something went wrong!</StyledTitle>
        <StyledMessageTitle>
          Our engineers have been notified.
          <br />
          Sorry for the inconvenience!
        </StyledMessageTitle>
        <PrimaryButton onClick={resetErrorBoundary} label="Try again!" />
        {APP_ENV !== "production" && (
          <StyledMessageTitle style={{ color: "red" }}>
            {error}
          </StyledMessageTitle>
        )}
      </ErrorWrapper>
    </ErrorContainer>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.string,
  resetErrorBoundary: PropTypes.func
};

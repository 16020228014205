import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Textarea } from "baseui/textarea";
import { primaryBlack } from "../../utils";

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const StyledTextArea = styled(Textarea)`
  font-family: "Montserrat", sans-serif;
`;

const StyledLabel = styled.label`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 8px;
  color: ${primaryBlack};
  display: inline-block;
`;

const Label = ({ children, htmlFor, ...rest }) => (
  <StyledLabel htmlFor={htmlFor} {...rest}>
    {children}
  </StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired
};

export const TextArea = React.forwardRef((props, ref) => {
  const { name, label, ...rest } = props;
  return (
    <TextInputWrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledTextArea
        ref={ref}
        name={name}
        id={name}
        {...rest}
        overrides={{
          Input: {
            style: () => ({
              backgroundColor: "#fff"
            })
          }
        }}
        rows="4"
        cols="20"
      />
    </TextInputWrapper>
  );
});

TextArea.displayName = "TextArea";

TextArea.propTypes = {
  name: PropTypes.string,
  input: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node
};

TextArea.defaultProps = {
  label: "",
  error: undefined,
  children: null
};

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import { useParams } from "react-router";
import uuid from "react-uuid";

import {
  Body1,
  ErrorComponent,
  Layout,
  MetaDecorator,
  ProductCard,
  ProductCardSkeleton
} from "../../components";
import Queries from "../../graphql/queries";
import { average } from "../../utils";
import {
  BreadcrumbContainer,
  CategoryContainer,
  CategoryWrapper,
  Divider,
  EmptyStateContainer,
  EmptyStateText,
  ProductWrapper,
  StyledH1,
  TotalText,
  UnderLine,
  StyledP
} from "./Categories.styled";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

const overrides = {
  Root: {
    style: () => ({
      borderRadius: "10px"
    })
  }
};

export default function CategoryDetail() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(Queries.GET_CATEGORY, {
    variables: { categoryId: id }
  });
  useEffect(() => {
    analytics.logEvent(Log.screens.CATEGORY_PAGE_VIEWED);
  }, []);

  if (error) return <ErrorComponent error={error.message} />;
  if (!data && loading)
    return (
      <Layout>
        <CategoryContainer>
          <BreadcrumbContainer>
            <Skeleton height="20px" width="180px" overrides={overrides} />
          </BreadcrumbContainer>
          <>
            <Skeleton height="40px" width="300px" overrides={overrides} />
            <Divider />
            <CategoryWrapper>
              <ProductWrapper>
                {Array.apply(null, Array(10)).map(() => (
                  <ProductCardSkeleton showBorder large key={uuid()} />
                ))}
              </ProductWrapper>
            </CategoryWrapper>
          </>
        </CategoryContainer>
      </Layout>
    );

  return (
    <Layout>
      <CategoryContainer>
        <MetaDecorator
          title={data.category.name}
          description={data.category.description || data.category.name}
        />
        <BreadcrumbContainer>
          <Breadcrumbs>
            <StyledLink to="/">Home</StyledLink>
            <span>Category</span>
            <span>{data.category.name}</span>
          </Breadcrumbs>
        </BreadcrumbContainer>
        <StyledH1>{data.category.name}</StyledH1>
        <StyledP>{data.category.description}</StyledP>
        <UnderLine />
        <Divider />
        <TotalText>
          {`${data.category && data.category.products.length} results`}
        </TotalText>
        <CategoryWrapper>
          <>
            <ProductWrapper>
              {data.category && data.category.products.length > 0 ? (
                data.category.products.map((product) => {
                  const {
                    id,
                    imageUrl,
                    name,
                    price,
                    provider,
                    reviews
                  } = product;
                  const rating = reviews?.map((review) =>
                    parseInt(review.rating)
                  );
                  return (
                    <ProductCard
                      showBorder
                      large
                      key={id}
                      imageUrl={imageUrl}
                      title={name.toLowerCase()}
                      provider={provider?.name}
                      providerId={provider.id}
                      productId={id}
                      price={price}
                      rating={average(rating)}
                      reviews={reviews.length}
                    />
                  );
                })
              ) : (
                <EmptyStateContainer>
                  <Body1>Sorry, your search did not match any products</Body1>
                  <EmptyStateText>
                    Try browsing some of our other Categories
                  </EmptyStateText>
                </EmptyStateContainer>
              )}
            </ProductWrapper>
          </>
        </CategoryWrapper>
      </CategoryContainer>
    </Layout>
  );
}

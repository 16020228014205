const events = {
  // Home
  APP_LAUNCHED: "app_launched",
  SEARCH_BAR_BUTTON_CLICKED: "search_bar_button_clicked",
  HOME_BUTTON_CLICKED: "home_button_clicked",
  SOCIAL_BUTTON_CLICKED: "social_button_clicked",
  FAVORITE_BUTTON_CLICKED: "favorite_button_clicked",
  PROFILE_HEADER_TAPPED: "profile_header_tapped",
  LOG_OUT_BUTTON_CLICKED: "log_out_button_clicked",

  // Products
  VIEW_PRODUCT_ITEM: "view_product_item",
  SELECT_PRODUCT_ITEM: "select_product_item",
  SHOP_BUTTON_CLICKED: "shop_button_clicked",
  ADD_TO_FAVORITE_BUTTON_CLICKED: "add_to_favorite_button_clicked",

  // Blog
  BLOG_ITEM_CLICKED: "blog_item_clicked",

  // Categories
  SELECT_CATEGORY: "select_category",
  VIEW_CATEGORIES: "view_categories",

  // Comments & Reviews
  MORE_COMMENTS_BUTTON_CLICKED: "more_comments_button_clicked",
  MORE_REVIEWS_BUTTON_CLICKED: "more_reviews_button_clicked",
  WRITE_A_REVIEW_BUTTON_CLICKED: "write_a_review_button_clicked",

  POST_A_COMMENT_BUTTON_CLICKED: "post_a_comment_button_clicked",
  USER_FAVORITES_VIEWED: "user_favorites_viewed",

  // Legal
  POLICY_LINK_CLICKED: "policy_link_clicked"
};

const screens = {
  HOME_PAGE_VIEWED: "home_page_viewed",
  PRODUCT_DETAIL_PAGE_VIEWED: "product_detail_page_viewed",
  PRODUCT_REVIEW_PAGE_VIEWED: "product_review_page_viewed",
  BLOG_PAGE_VIEWED: "blog_page_viewed",
  BLOG_DETAIL_VIEWED: "blog_detail_viewed",
  CATEGORY_PAGE_VIEWED: "category_page_viewed",
  ALL_CATEGORIES_PAGE_VIEWED: "all_categories_page_viewed",
  FAVORITE_PAGE_VIEWED: "favorite_page_viewed",
  POLICY_PAGE_VIEWED: "policy_page_viewed"
};

export default {
  events,
  screens
};

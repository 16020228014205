import React from "react";
import styled from "styled-components";

import { useAuth } from "../../../contexts/AuthContext";
import { Avatar } from "baseui/avatar";
import { white, lightGray } from "../../../utils";

const HeaderContainer = styled.div`
  background-color: ${white};
  border-bottom: 1px solid ${lightGray};
`;

const HeaderWrapper = styled.div`
  display: flex;
  padding: 1rem 2rem;
  flex-direction: row;
  align-items: center;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const UserName = styled.h4`
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
`;

function Nav() {
  const { user } = useAuth();
  const isAuthenticated = user && user.user && user.user.name;
  return (
    <HeaderContainer>
      <HeaderWrapper>
        {isAuthenticated && (
          <UserContainer>
            <Avatar
              name={user.user?.name}
              size="scale900"
              src={user.user?.imageUrl}
            />
            <UserName>{`Yo, ${user.user?.name}`}</UserName>
          </UserContainer>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default Nav;

import React from "react";
import { NavHeader } from "./NavBar";
import { Header } from "./Header";

const HeaderComponent = () => {
  return (
    <>
      <Header />
      <NavHeader />
    </>
  );
};

export default HeaderComponent;

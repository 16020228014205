import React from "react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";

export const PostContent = styled.div`
  margin-top: 40px;
  @media screen and (max-width: 720px) {
    font-size: 16px;
    line-height: 27px;
  }
`;

export const PagesComponent = ({ post }) => {
  <PostContent>
    <Markdown options={{ forceBlock: true }}>{post}</Markdown>
  </PostContent>;
};

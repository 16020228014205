import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const avatarSizeMap = {
  xLarge: 120,
  large: 48,
  medium: 40,
  small: 32
};

const AvatarWrapper = styled.div`
  position: relative;
  border-radius: ${(props) => avatarSizeMap[props.size] / 2}px;
  width: ${(props) => avatarSizeMap[props.size]}px;
  height: ${(props) => avatarSizeMap[props.size]}px;
  text-align: center;
  background-color: #eeeeee;
`;

const AvatarImage = styled.img`
  border-radius: ${(props) => avatarSizeMap[props.size] / 2}px;
  display: block;
  object-fit: cover;
  width: ${(props) => avatarSizeMap[props.size]}px;
`;

const Placeholder =
  "https://res.cloudinary.com/ds0w1lnot/image/upload/v1611081994/user_1_fuc49i.svg";

export const Avatar = (props) => {
  const { imageUrl, size } = props;
  return (
    <AvatarWrapper size={size} {...props}>
      {imageUrl !== null ? (
        <AvatarImage
          size={size}
          src={imageUrl}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = Placeholder;
          }}
        />
      ) : (
        <AvatarImage size={size} src={Placeholder} />
      )}
    </AvatarWrapper>
  );
};

Avatar.defaultProps = {
  size: "large",
  imageUrl: null
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["xLarge", "large", "medium", "small"]),
  imageUrl: PropTypes.string
};

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Input } from "baseui/input";

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

const StyledTextInput = styled(Input)`
  font-family: "Montserrat", sans-serif;
`;

export class TextInput extends React.Component {
  componentDidMount() {
    const { input } = this.props;
    input(this.input);
  }

  render() {
    const { name, error, children, ...rest } = this.props;
    return (
      <TextInputWrapper>
        {children}
        <StyledTextInput
          ref={(ref) => {
            this.input = ref;
          }}
          type="text"
          error={error !== undefined}
          name={name}
          id={name}
          overrides={{
            Input: {
              style: () => ({
                backgroundColor: "#fff"
              })
            }
          }}
          {...rest}
        />
      </TextInputWrapper>
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string,
  input: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node
};

TextInput.defaultProps = {
  label: "",
  input: () => {},
  error: undefined,
  children: null
};

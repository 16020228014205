import React from "react";
import { useQuery } from "@apollo/client";
import Markdown from "markdown-to-jsx";
import Queries from "../../graphql/queries";

import {
  PageContainer,
  PageWrapper,
  PageTitle,
  LegalDate
} from "./Pages.styled";

import { Loading, Layout, ErrorComponent } from "../../components";
import { formatDate } from "../../utils";

export default function TermsOfService() {
  const { loading, error, data } = useQuery(Queries.GET_PAGE, {
    variables: {
      slug: "terms-of-service"
    }
  });

  if (error) return <ErrorComponent error={error.message} />;
  return (
    <Layout>
      <PageContainer>
        <PageWrapper>
          {!data && loading ? (
            <Loading />
          ) : (
            <>
              <PageTitle>{data.page.title}</PageTitle>
              <LegalDate>
                {`Last updated: ${formatDate(data.page.updatedAt)}`}
              </LegalDate>
              <Markdown options={{ forceBlock: true }}>
                {data && data.page.content.markdown}
              </Markdown>
            </>
          )}
        </PageWrapper>
      </PageContainer>
    </Layout>
  );
}

import gql from "graphql-tag";
import FRAGMENTS from "./fragments";

const GET_POSTS = gql`
  query getPosts {
    posts: posts(stage: PUBLISHED, locales: [en], orderBy: publishedAt_DESC) {
      ...PostFragment
    }
  }
  ${FRAGMENTS.POST_FRAGMENT}
`;

const GET_POST = gql`
  query getPost($slug: String!) {
    post(where: { slug: $slug }, locales: en, stage: PUBLISHED) {
      ...PostFragment
    }
  }
  ${FRAGMENTS.POST_FRAGMENT}
`;

const GET_FAVORITES = gql`
  query getFavorites($userId: ID!) {
    favorites: getFavorites(userId: $userId) {
      ...FavoriteFragment
    }
  }
  ${FRAGMENTS.FAVORITE_FRAGMENT}
`;

const GET_COMMENTS = gql`
  query getComments($postId: ID!) {
    comment: comments(postId: $postId) {
      ...CommentFragment
    }
  }
  ${FRAGMENTS.COMMENTS_FRAGMENT}
`;

const GET_HERO_IMAGES = gql`
  query heroImagesWithLinksConnection($stage: Stage) {
    heroImages: heroImagesWithLinksConnection(stage: $stage) {
      ...HeroFragment
    }
  }
  ${FRAGMENTS.HERO_IMAGES}
`;

const GET_REVIEWS = gql`
  query getReviews($productId: ID!) {
    reviews: getReviews(productId: $productId) {
      ...ReviewFragment
    }
  }
  ${FRAGMENTS.REVIEW_FRAGMENT}
`;

const GET_REVIEWS_ADMIN = gql`
  query getReviews {
    reviews: getReviews(type: "all") {
      ...ReviewFragment
    }
  }
  ${FRAGMENTS.REVIEW_FRAGMENT}
`;

const GET_COMMENTS_ADMIN = gql`
  query getComments {
    comment: comments(type: "all") {
      ...CommentFragment
    }
  }
  ${FRAGMENTS.COMMENTS_FRAGMENT}
`;

const GET_PRODUCTS = gql`
  query Products {
    products: getProducts {
      ...ProductsFragment
    }
  }
  ${FRAGMENTS.PRODUCT_FRAGMENT}
`;

const GET_PRODUCT_BY_ID = gql`
  query Product($productId: ID!) {
    product: getProduct(productId: $productId) {
      ...ProductsFragment
    }
  }
  ${FRAGMENTS.PRODUCT_FRAGMENT}
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories: getCategories {
      ...CategoryFragment
    }
  }
  ${FRAGMENTS.CATEGORY_FRAGMENT}
`;

const GET_CATEGORY = gql`
  query Category($categoryId: ID!) {
    category: getCategory(categoryId: $categoryId) {
      ...CategoryFragment
    }
  }
  ${FRAGMENTS.CATEGORY_FRAGMENT}
`;

const GET_PROVIDERS = gql`
  query Providers {
    providers: getProviders {
      ...ProviderFragment
    }
  }
  ${FRAGMENTS.PROVIDERS_FRAGMENT}
`;

const GET_PROVIDER = gql`
  query Category($providerId: ID!) {
    provider: getProvider(providerId: $providerId) {
      ...ProviderFragment
    }
  }
  ${FRAGMENTS.PROVIDERS_FRAGMENT}
`;

const GET_USERS = gql`
  query Users {
    users: getUsers {
      ...UserFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`;

const GET_PAGE = gql`
  query getPage($slug: String!) {
    page(where: { slug: $slug }, locales: en, stage: PUBLISHED) {
      ...PageFragment
    }
  }
  ${FRAGMENTS.PAGE_FRAGMENT}
`;

export default {
  GET_POSTS,
  GET_POST,
  GET_COMMENTS,
  GET_HERO_IMAGES,
  GET_FAVORITES,
  GET_REVIEWS,
  GET_REVIEWS_ADMIN,
  GET_COMMENTS_ADMIN,
  GET_PRODUCTS,
  GET_CATEGORIES,
  GET_PROVIDERS,
  GET_CATEGORY,
  GET_PROVIDER,
  GET_USERS,
  GET_PRODUCT_BY_ID,
  GET_PAGE
};

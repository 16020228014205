import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Body2, Body3 } from "../Heading/Heading";
import Placeholder from "../../assets/images/placeholder.png";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px; ;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
`;

const ProductImageContainer = styled.div`
  width: 60px;
  height: 60px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const ProductTitle = styled(Body2)`
  &:hover {
    text-decoration: underline;
  }
`;

const ProductPrice = styled(Body3)`
  &:hover {
    text-decoration: underline;
  }
`;

export const FeaturedProduct = ({ title, imageUrl, price }) => {
  return (
    <CardContainer>
      <CardWrapper>
        <ProductImageContainer>
          <ProductImage
            alt={title}
            src={imageUrl === null ? Placeholder : imageUrl}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = Placeholder;
            }}
          />
        </ProductImageContainer>
        <ProductDetailsWrapper>
          <ProductTitle>{title}</ProductTitle>
          <ProductPrice>{price}</ProductPrice>
        </ProductDetailsWrapper>
      </CardWrapper>
    </CardContainer>
  );
};

FeaturedProduct.propTypes = {
  /**
   URL of the product image
    */
  imageUrl: PropTypes.string,
  /**
    The name of the product
    */
  title: PropTypes.string,
  price: PropTypes.string
};

export const PRODUCT_COLUMNS = [
  "Product Image",
  "Product Name",
  "Provider",
  "Category",
  "Price",
  "Status",
  "Featured",
  "Action",
  "Action"
];

export const PRODUCT_REVIEW_COLUMNS = [
  "Product",
  "Rating",
  "Review",
  "Review By",
  "Status",
  "Action"
];

export const COMMENT_COLUMNS = [
  "Author",
  "Comment",
  "Status",
  "Blog Post",
  "Submitted on",
  "Action"
];

export const COMMENT_THREADS_COLUMNS = [
  "Author",
  "Comment",
  "Response to",
  "Status",
  "Blog Post",
  "Action"
];

export const CATEGORY_COLUMNS = [
  "Category Name",
  "Description",
  "Products",
  "Action",
  "Action"
];

export const PROVIDER_COLUMNS = [
  "Provider Name",
  "ImageUrl",
  "Url",
  "Products",
  "Action",
  "Action"
];

export const USER_COLUMNS = [
  "User Name",
  "Email",
  "User Role",
  "Social Login",
  "Action"
];

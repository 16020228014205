import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Heart, Layout } from "react-feather";
import { Button } from "baseui/button";
import ChevronDown from "baseui/icon/chevron-down";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { DropDown } from "../../components";

import { primaryBlack } from "../../utils";
import AuthUtils from "../../utils/Auth";
import { useAuth } from "../../contexts/AuthContext";
import { SearchBar } from "../TextFields/SearchBar";
import Log from "../../config/analytics";
import { analytics } from "../../config/firebase";

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  background-color: ${primaryBlack};
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 40px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLogo = styled.img`
  display: flex;
  justify-content: flex-start;
  height: 40px;
  width: 81px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
`;

const NavlinkWrapper = styled.nav`
  display: flex;
  /* flex: 1 0 0; */
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-left: auto; */
`;

const Navlink = styled(Link)``;

const RegisterButton = styled.div`
  display: flex;
  padding-left: 24px;
  align-items: center;
`;

export const Header = () => {
  const { user, updateUser, updateTokens } = useAuth();
  const history = useHistory();
  const isAuthenticated = user && user.user && user.user.name;
  const role = user && user.user && user.user.role;

  const handleLogout = () => {
    if (isAuthenticated) {
      AuthUtils.clearStorage();
      updateTokens({});
      updateUser({});
      analytics.setCurrentScreen(user);
      analytics.logEvent(Log.events.LOG_OUT_BUTTON_CLICKED);
      history.push("/");
    }
  };

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Link to="/">
          {/* TODO put images in a dynamic place*/}
          <HeaderLogo src={"https://media.graphcms.com/xZi9kispSv6uSuF8XPA6"} />
        </Link>
        <SearchContainer>
          <SearchBar />
        </SearchContainer>
        <NavlinkWrapper>
          {isAuthenticated && (
            <>
              {role === "admin" && (
                <Navlink to="/admin">
                  <Button startEnhancer={() => <Layout size={24} />} />
                </Navlink>
              )}
              <Navlink to="/favorites">
                <Button startEnhancer={() => <Heart size={24} />} />
              </Navlink>
              <StatefulPopover
                focusLock
                placement={PLACEMENT.bottomLeft}
                content={() => (
                  <DropDown
                    items={["Sign out"]}
                    onOptionClicked={() => handleLogout()}
                  >
                    {" "}
                    <Heart size={24} />
                  </DropDown>
                )}
              >
                <Button endEnhancer={() => <ChevronDown size={24} />}>
                  {`Yo, ${user.user.name}`}
                </Button>
              </StatefulPopover>
            </>
          )}
          {!isAuthenticated && (
            <RegisterButton>
              <Navlink to="/auth">
                <Button>Sign In</Button>
              </Navlink>
              <Navlink to="/auth">
                <Button>Sign Up</Button>
              </Navlink>
            </RegisterButton>
          )}
        </NavlinkWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { white } from "../../utils";
import Placeholder from "../../assets/images/placeholder.png";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;
const ProductImage = styled.img`
  max-width: 100%;
  padding: 32px;
  height: 450px;
  object-fit: contain;
  background-color: #f8f8f8;
  @media screen and (max-width: 720px) {
    width: 100%;
    height: 300px;
    padding: 0px 0px;
    background-color: transparent;
  }
`;

export const ProductDetailCard = ({ title, imageUrl, ...props }) => {
  return (
    <CardContainer {...props}>
      <ProductImage
        alt={title}
        src={imageUrl === null ? Placeholder : imageUrl}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = Placeholder;
        }}
      />
    </CardContainer>
  );
};
ProductDetailCard.propTypes = {
  /**
   URL of the product image
    */
  imageUrl: PropTypes.string,
  title: PropTypes.string
};

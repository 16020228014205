import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Queries from "../../graphql/queries";
import {
  BlogCard,
  Loading,
  Layout,
  ErrorComponent,
  H1,
  MetaDecorator
} from "../../components";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import {
  BlogContainer,
  BreadcrumbContainer,
  H1TitleWrapper
} from "./Blog.styled";

import { analytics } from "../../config/firebase";
import Log from "../../config/analytics";

export default function BlogPage() {
  useEffect(() => {
    analytics.logEvent(Log.screens.BLOG_PAGE_VIEWED);
  }, []);
  const { loading, data, error } = useQuery(Queries.GET_POSTS);

  if (error) return <ErrorComponent error={error.message} />;
  return (
    <Layout>
      <BlogContainer>
        <MetaDecorator
          title={"SnkrMart - Blog"}
          description={
            "Find the latest news on SnkrMart, including the most recent releases, highlights, and more."
          }
        />
        <>
          <BreadcrumbContainer>
            <Breadcrumbs>
              <StyledLink href="/">Home</StyledLink>
              <span>Blog </span>
            </Breadcrumbs>
          </BreadcrumbContainer>
          <H1TitleWrapper>
            <H1>I don&apos;t do too much blogging</H1>
          </H1TitleWrapper>
          {!data && loading && <Loading />}
          <FlexGrid
            flexGridColumnCount={[1, 1, 2, 3]}
            flexGridColumnGap="scale900"
            flexGridRowGap="scale1000"
          >
            {data?.posts.map((post) => {
              const {
                url = "https://media.graphcms.com/output=format:jpg/k6o8wBkZSIeqBrhZk450"
              } = post.coverImage;
              const {
                name = "Snkr Mart",
                picture = {
                  url: "https://media.graphcms.com/k6o8wBkZSIeqBrhZk450"
                }
              } = { ...post.author };
              return (
                <FlexGridItem key={post.id}>
                  <Link label="blog" to={`/blog/${post.slug}/`} key={post.slug}>
                    <BlogCard
                      coverImage={url}
                      blogTitle={post?.title}
                      excerpt={post.excerpt}
                      authorAvatar={picture?.url}
                      authorName={name}
                      publishedDate={post?.publishedAt}
                      tags={post?.tags || []}
                    />
                  </Link>
                </FlexGridItem>
              );
            })}
          </FlexGrid>
        </>
      </BlogContainer>
    </Layout>
  );
}

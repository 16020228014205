import { Link } from "react-router-dom";
import styled from "styled-components";
import { H1, Subtitle3 } from "../../components";

export const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 40px 24px 40px;
  min-height: 100vh;
  @media screen and (max-width: 720px) {
    padding: 40px 16px;
    margin: 0;
  }
`;

export const BlogHeightWrapper = styled.div`
  min-height: 100vh;
  @media screen and (max-width: 720px) {
    min-height: unset;
  }
`;

export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

export const BlogHeaderContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const GoBackButton = styled.div`
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const BlogTitle = styled(H1)`
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.04em;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;
  @media screen and (max-width: 720px) {
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 8px;
    text-align: left;
  }
`;
export const ExcerptText = styled(Subtitle3)`
  text-align: center;
  margin-bottom: 8px;
  font-size: 14px;
  opacity: 0.7;
  font-weight: 400;
  @media screen and (max-width: 720px) {
    font-size: 14px;
    text-align: left;
  }
`;

export const BlogDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  align-self: center;
  margin-left: 6em;
`;

export const CoverImageContainer = styled.div`
  height: 550px;
  @media screen and (max-width: 720px) {
    width: 100%;
    height: 235px;
  }
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  @media screen and (max-width: 720px) {
    border-radius: 2px;
  }
`;

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0 20px 0;
`;

export const AuthorAvatarContainer = styled.div`
  align-items: center;
`;

export const AuthorAvatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

export const AuthorName = styled.h3`
  font-family: "Work Sans", sans-serif;
  margin: 0;
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @media screen and (max-width: 720px) {
    text-align: left;
  }
`;

export const BlogDate = styled.h3`
  font-family: "Work Sans", sans-serif;
  margin: 0;
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
  letter-spacing: 0.05em;
  @media screen and (max-width: 720px) {
    text-align: left;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  @media screen and (max-width: 720px) {
    text-align: left;
  }
`;
export const BlogContent = styled.div`
  margin-top: 40px;
  @media screen and (max-width: 720px) {
    font-size: 16px;
    line-height: 27px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  align-items: center;
`;

export const TagWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 8px;
`;

export const Tags = styled.h4`
  font-family: "Cabin", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #151515;
  margin: 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  margin: 8px 0 24px 0;
`;

export const CommentHeader = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
`;

export const AccountText = styled.p`
  font-size: 16px;
`;

export const UnauthenticatedWrapper = styled.p`
  margin: 24px 0;
`;

export const AccountLinkText = styled(Link)`
  font-size: 16px;
  text-decoration: underline;
`;

export const CommentTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  margin: 0 0 24px 0;
`;

export const ThreadContainer = styled.div`
  display: flex;
`;

export const BreadcrumbContainer = styled.div`
  margin-bottom: 16px;
`;

export const Attributes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ThreadLine = styled.div`
  border-left: 2.5px solid #edeff1;
  margin-top: 16px;
  border-top-left-radius: 10px;
`;

export const Separator = styled.span`
  padding: 2px;
  /* align-self: center; */
`;

export const CommentSectionContainer = styled.div`
  padding-bottom: 40px;
  @media screen and (max-width: 720px) {
    padding: 0 20px;
  }
`;

export const H1TitleWrapper = styled.div`
  margin: 24px 0;
  @media screen and (max-width: 720px) {
    margin: 0 0 16px 0;
  }
`;

import styled from "styled-components";

import { primaryBlack, white, mediumGray } from "../../utils";

const ColumnStyles = `
display: flex;
flex-direction: column;
`;

const RowStyles = `
display: flex;
flex-direction: row;
`;

const PStyled = `
color: ${primaryBlack};
font-family: "Montserrat", sans-serif;
font-size: 12px;
line-height: 1.25rem;
font-weight: 500;
margin: 0;
text-overflow: ellipsis;
overflow: hidden;
line-height: 25px;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical
`;

export const PageContainer = styled.div`
  ${ColumnStyles}
  padding: 0rem 2rem;
`;

export const PageTitle = styled.h1`
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
`;

export const PageWrapper = styled.div`
  ${ColumnStyles}
  width: 100%;
  margin: 0 auto;
`;

export const ProductContainer = styled.div`
  ${RowStyles}
  align-items: center;
`;

export const UserContainer = styled.div`
  ${RowStyles}
  align-items:center;
`;

export const ReviewContainer = styled.div`
  ${ColumnStyles}
`;

export const ProductImage = styled.img`
  height: 3.5rem;
  width: 4rem;
  border-radius: 0.5rem;
  object-fit: contain;
`;

export const ProductNameContainer = styled.div`
  margin-left: 1.5rem;
`;

export const ProductName = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-transform: capitalize;
  color: ${primaryBlack};
`;

export const StyledSubjectText = styled.h2`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${primaryBlack};
  text-transform: capitalize;
  margin-bottom: 0.25rem;
`;

export const ModalWrapper = styled.div`
  padding: 2rem 1rem;
`;

export const InputContainer = styled.div`
  margin: 32px 0;
`;

export const InputWrapper = styled.div`
  margin: 32px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export const HeaderSection = styled.div`
  ${RowStyles}
  align-items: center;
  background-color: ${white};
  padding: 1rem 3rem;
  width: 100%;
  margin: 1.5rem 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const BlogContainer = styled.div`
  ${ColumnStyles}
  cursor: pointer;
`;

export const StyledText = styled.p`
  ${PStyled}
`;

export const BlogTimeStamp = styled.h3`
  font-family: "Cabin", sans-serif;
  margin: 0;
  font-size: 12px;
  line-height: 130%;
  font-weight: 500;
  color: ${mediumGray};
  text-transform: capitalize;
`;

export const ActionsWrapper = styled.a`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 8px;
  transition: color 0.3s;
  color: ${mediumGray};
  &:hover {
    color: ${primaryBlack};
  }
`;

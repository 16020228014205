import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

import animationData from "../../assets/lotties/spinner.json";

const LoadingContainer = styled.div`
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media screen and (max-width: 720px) {
    min-height: 50vh;
  }
`;

export const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <LoadingContainer>
      <Lottie options={defaultOptions} height={50} width={50} />
    </LoadingContainer>
  );
};
